import React, { useState, useEffect, useRef } from 'react'
import { motion, AnimatePresence } from 'framer-motion';

const colors = ["#4A837F","#569278","#8AB29A", "#A1CEA0","#C5DBB8", "#B7E8C5","#A8DFD2","#8DC7B9","#569FA9"]

const RemoteScoreView = ({size, scoreInfo, isArchive}) => {
  const containerStyle = {
    width: '100%',
    height: size * (115 / 300),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  }

  console.log(scoreInfo.remoteScoreInfo)
  const histogramX = 110
  const histogramW = 170
  const histogramBuckets = scoreInfo.remoteScoreInfo.buckets ? scoreInfo.remoteScoreInfo.buckets.length : 0
  const buckets = scoreInfo.remoteScoreInfo.buckets
  const bucketW = 20
  const spacing = (histogramW - (histogramBuckets * bucketW)) / (histogramBuckets)
  
  const maxBucket = buckets.reduce((max, item) => Math.max(max, item.count), 0)
  const labels = ['<10', '<15', '<20', '<30', '<50', '50+']
  return <svg style={containerStyle} viewBox={`0 0 300 115`}>
    {
      (scoreInfo.remoteScoreInfo && scoreInfo.remoteScoreInfo.buckets) && 
      <g>
        <text fill={"#314f4d"} x={20} y={30} fontSize={'25px'} fontFamily={'ProtoMono-Regular'} textAnchor='left' dominantBaseline={'middle'}>
          {scoreInfo.bestScore}
        </text>
        <text fill={"#314f4d"} x={20} y={50} fontSize={'10px'} fontFamily={'ProtoMono-Regular'} textAnchor='left' dominantBaseline={'middle'}>
          Your Score
        </text>
        <text fill={"#314f4d"} x={20} y={79} fontSize={'25px'} fontFamily={'ProtoMono-Regular'} textAnchor='left' dominantBaseline={'middle'}>
          {scoreInfo.remoteScoreInfo.highScore}
        </text>
        <text fill={"#314f4d"} x={20} y={100} fontSize={'10px'} fontFamily={'ProtoMono-Regular'} textAnchor='left' dominantBaseline={'bottom'}>
          Global Best
        </text>
        {
          buckets.map((bucket, i) => {
            const height = 90 * (bucket.count / maxBucket) 
            const adjHeight = height == 0 ? 2 : height
            return <g key={i}>
              <motion.rect x={histogramX + i * spacing + i * bucketW}
                y={90 - adjHeight} width={bucketW} height={adjHeight} fill={colors[i]}>
              </motion.rect>
            </g>
          })
        }
        {
          labels.map((label, i) => {
            const x = histogramX + i * spacing + i * bucketW
            return <text x={x} y={100}
              key={i} 
              fontSize={'10px'} fontFamily={'ProtoMono-Regular'} 
              textAnchor='center' dominantBaseline={'bottom'}fill={"#314f4d"}>
            {label}
          </text>
          })
        }
      </g>
    }
  </svg>
}

export default RemoteScoreView;


