import React, { useState, useEffect, useRef } from 'react'
import { motion, AnimatePresence } from 'framer-motion';

const colors = ["#4A837F","#569278","#8AB29A", "#A1CEA0","#C5DBB8", "#B7E8C5","#A8DFD2","#8DC7B9","#569FA9"]

const textStyle = {
  userSelect: 'none',
  WebkitUserSelect: 'none',
  MozUserSelect: 'none',
  msUserSelect: 'none',
  background: 'none', 
  pointerEvents: 'none'
};

const Number = ({n, gridSize, margin}) => {


  const numberSqSize = ((gridSize - margin * 3)/ 3)

  return (
    <motion.g>
      <polygon
        points={`
          0,0
          0,${numberSqSize}
          ${numberSqSize},${numberSqSize}
          ${numberSqSize},0
        `}
        style={{
          fill: colors[n - 1],
        }}
      />
      <text style={textStyle} x={numberSqSize/2} y={numberSqSize/2} fontSize={`${Math.floor(numberSqSize * .65)}px`} fontFamily='ProtoMono-Regular' textAnchor='middle' dominantBaseline={'middle'} fill='#ffffff'>
        {n}
      </text>
    </motion.g>
  )
}

const LeftChevronArrow = ({onClick, x, y, width, animations, index}) => {
  const arrowStyle = {
    originX: `${x + width / 2}px`,
    originY: `${y + width * .8}px`,
    cursor: "pointer",
    fill:"#569FA9",
    strokeLinejoin: "round"
  }

  const chevronPoints = `${width / 2},${width}
    ${width},${width / 2},
    ${width},${0},
    ${width / 2},${width / 2}
    ${0},${0} 
    ${0},${width / 2}`

  return <motion.g transform={`translate(${x/2} ${y}) rotate(90)`}>        
        <motion.path 
          key='solidArrowForHover'
          d={polygonToPath(chevronPoints)}
          style={arrowStyle}
        />
    </motion.g>
}

const RightChevronArrow = ({onClick, x, y, width, animations, index}) => {
  const arrowStyle = {
    originX: `${x + width / 2}px`,
    originY: `${y + width * .8}px`,
    cursor: "pointer",
    fill:"#569FA9",
    strokeLinejoin: "round"
  }

  const chevronPoints = `${width / 2},${width}
    ${width},${width / 2},
    ${width},${0},
    ${width / 2},${width / 2}
    ${0},${0} 
    ${0},${width / 2}`

  return <motion.g transform={`translate(${x - 24} ${y + width}) rotate(-90)`}>        
        <motion.path 
          key='solidArrowForHover'
          d={polygonToPath(chevronPoints)}
          style={arrowStyle}
        />
    </motion.g>
}

const LeftGreenTriangle = ({x, y, width}) => {
  const arrowStyle = {
    originX: `${x + width / 2}px`,
    originY: `${y + width / 4}px`,
    cursor: "pointer",
    fill:"#A1CEA0",
    strokeLinejoin: "round"
  }

  const trianglePoints = `0,0
    ${width/2},${width / 2},
    ${width},${0}`

  return <motion.g transform={`translate(${x/2 + 8} ${y}) rotate(90)`}>        
        <motion.path 
          key='solidArrowForHover'
          d={polygonToPath(trianglePoints)}
          style={arrowStyle}
        />
    <g transform={`rotate(${90} ${width/2} ${width * .19})`}>

      <text style={textStyle} x={width/2} y={width * .19} fontSize={`${Math.floor(width/2)}px`} fontFamily='ProtoMono-Regular' textAnchor='middle' dominantBaseline={'middle'} fill='#ffffff'>
        {'-'}
      </text>
    </g>
    </motion.g>
}

const RightGreenTriangle = ({x, y, width}) => {
  const arrowStyle = {
    originX: `${width / 2}px`,
    originY: `${width / 2}px`,
    cursor: "pointer",
    fill:"#A1CEA0",
    strokeLinejoin: "round"
  }

  const trianglePoints = `0,0
    ${width/2},${width / 2},
    ${width},${0}`

  return <motion.g transform={`translate(${x - 30} ${y + width}) rotate(-90)`}>        
    <motion.path 
      key='solidArrowForHover'
      d={polygonToPath(trianglePoints)}
      style={arrowStyle}
    />
    <text style={textStyle} x={width/2} y={width * .19} fontSize={`${Math.floor(width/2)}px`} fontFamily='ProtoMono-Regular' textAnchor='middle' dominantBaseline={'middle'} fill='#ffffff'>
      {'+'}
    </text>
    </motion.g>
}

function polygonToPath(points) {
  const pointArray = points.split("\n");
  const pathCommands = [];

  // Start the path at the first point
  const [startX, startY] = pointArray[0].split(",");

  pathCommands.push(`M${startX},${startY}`);

  // Iterate over the remaining points
  for (let i = 1; i < pointArray.length; i++) {
    const [x, y] = pointArray[i].split(",");
    pathCommands.push(`L${x},${y}`);
  }

  // Close the path
  pathCommands.push("Z");

  return pathCommands.join(" ");
}



const ChevronDemo = ({}) => {
  const margin = 5
  const gridSize = 290
  const numberSqSize = ((gridSize - margin * 3)/ 3)

  const pNeg = (margin/2 + -1 * (numberSqSize + margin))
  const p1 = (margin/2 + 0 * (numberSqSize + margin))
  const p2 = (margin/2 + 1 * (numberSqSize + margin))
  const p3 = (margin/2 + 2 * (numberSqSize + margin))
  const pPos = (margin/2 + 3 * (numberSqSize + margin))

  const duration = 6
  const transition = {
    duration: duration,
    repeat: Infinity,
    repeat: 'loop',
    ease: 'backOut'
  }
  const transitionButton = {
    duration: duration,
    times: [0, 1/32, 1/16, 6/8, 25/32, 13/16, 1],
    repeat: Infinity,
    repeat: 'loop',
    ease: 'backOut'
  }
  const leftTransitionButton = {
    duration: duration,
    times: [2/8, 9/32, 5/16, 4/8, 17/32, 9/16, 1],
    repeat: Infinity,
    repeat: 'loop',
    ease: 'backOut'
  }
  return <svg width={'95%'} height='20%' viewBox={`-250 0 500 100`}> 
      <motion.g animate={{
        scale: [1, .9, 1, 1, .9, 1, 1],
        transition: leftTransitionButton,
      }}>
        <LeftChevronArrow
          x={-300 - margin}
          y={margin}
          width={numberSqSize - margin}
        />
      </motion.g>
    <motion.g animate={{
      scale: [1, .9, 1, 1, .9, 1, 1],
      transition: transitionButton,
    }}>
        <RightChevronArrow
          x={175 + margin / 2}
          y={margin}
          width={numberSqSize - margin}
        />
    </motion.g>
    
    <svg x="-145" y={0} width={gridSize} height="100">
    <motion.g animate={{
        x: [pNeg, p1, p1, pNeg, pNeg, pNeg, pNeg, pNeg, pNeg],
        transition: transition,
        y: margin/2
      }}>
        <Number
          n={3}
          gridSize={gridSize}
          margin={margin}
        />
      </motion.g>
      <motion.g animate={{
        x: [p1, p2, p2, p1, p1, pNeg, pNeg, p1, p1],
        transition: transition,
        y: margin/2
      }}>
        <Number
          n={1}
          gridSize={gridSize}
          margin={margin}
        />
      </motion.g>
      <motion.g animate={{
        x: [p2, p3, p3, p2, p2, p1, p1, p2, p2],
        transition: transition,
        y: margin/2
      }}>
        <Number
          n={2}
          gridSize={gridSize}
          margin={margin}

        />
      </motion.g>
      <motion.g animate={{
        x: [p3, pPos, pPos, p3, p3, p2, p2, p3, p3],
        transition: transition,
        y: margin/2
      }}>
        <Number
          n={3}
          gridSize={gridSize}
          margin={margin}

        />
      </motion.g>
      <motion.g animate={{
        x: [pPos, pPos, pPos, pPos, pPos, p3, p3, pPos, pPos],
        transition: transition,
        y: margin/2
      }}>
        <Number
          n={1}
          gridSize={gridSize}
          margin={margin}
        />
      </motion.g>
    </svg>
  </svg> 
}


const PlusMinusDemo = ({}) => {
  const [currentValue, setCurrentValue] = useState(3)
  const margin = 7
  const gridSize = 290
  const numberSqSize = ((gridSize - margin * 3)/ 3)

  const pNeg = (margin/2 + -1 * (numberSqSize + margin))
  const p1 = (margin/2 + 0 * (numberSqSize + margin))
  const p2 = (margin/2 + 1 * (numberSqSize + margin))
  const p3 = (margin/2 + 2 * (numberSqSize + margin))
  const pPos = (margin/2 + 3 * (numberSqSize + margin))

  const plusMinusTimes = [0, 1/12, 2/12, 3/12, 4/12, 5/12, 6/12, 7/12, 8/12, 9/12, 10/12, 11/12, 1]
  const plusMinusTimes2 = [0, 1/32, 1/16, 2/8, 9/32, 5/16, 4/8, 17/32, 9/16, 6/8, 25/32, 13/16, 1]

  const subtractedArr = plusMinusTimes.map((element) => {
    if (element !== 1) {
      return element - (1/24);
    }
    return element;
  });

  const duration = 6
  const leftTransitionButton = {
    duration: duration,
    times: [2/8, 9/32, 5/16, 4/8, 17/32, 9/16, 1],
    repeat: Infinity,
    repeat: 'loop',
    ease: 'backOut'
  }
  const rightTransitionButton = {
    duration: duration,
    times: [0, 1/32, 1/16, 6/8, 25/32, 13/16, 1],
    repeat: Infinity,
    repeat: 'loop',
    ease: 'backOut'
  }
  const transitionPlusMinus = {
    scale: {
      duration: duration, 
      repeat: Infinity, 
      repeat: 'loop',
      times: plusMinusTimes2
    },
    count: {
      duration: duration, 
      repeat: Infinity, 
      repeat: 'loop',
      times: plusMinusTimes2
    },
  
  }

  const setNIncr = (latest) => {
    setCurrentValue(Math.round(latest.count))
  }

  return <svg width={'95%'} height='20%' viewBox={`-250 0 500 100`}> 
      <motion.g animate={{
        scale: [1, .9, 1, 1, .9, 1, 1],
        transition: leftTransitionButton
      }}>
        <LeftGreenTriangle
          x={-300 - margin * 3}
          y={margin / 2}
          width={numberSqSize}
        />
      </motion.g>
    <motion.g animate={{
      scale: [1, .9, 1, 1, .9, 1, 1],
      transition: rightTransitionButton,
    }}>
        <RightGreenTriangle
          x={175 + margin}
          y={margin / 2}
          width={numberSqSize}
        />
    </motion.g>
    
    <svg x="-145" y={0} width={gridSize} height="100">
      <motion.g 
      initial={{
        count: 3,
        x: p1
      }}
      animate={{
        x: p1,
        count: [3, 3, 4, 4, 4, 3, 3, 3, 2, 2, 2, 3, 3],
        // count: [3, 4, 3, 2, 3],
        // scale: [1, 1, 1.05, 1, 1, .9, 1, 1, .9, 1, 1, 1.05, 1],
        scale: [1, 1.05, 1, 1, .9, 1, 1, .9, 1, 1, 1.05, 1, 1],
        transition: transitionPlusMinus,
        y: margin/2
      }} onUpdate={(latest) => setNIncr(latest)}>
        <Number
          n={currentValue}
          gridSize={gridSize}
          margin={margin}
        />
      </motion.g>
      <motion.g initial={{x: p2}} animate={{
        x: p2,
        scale: [1, 1.05, 1, 1, .9, 1, 1, .9, 1, 1, 1.05, 1, 1],
        transition: transitionPlusMinus,
        y: margin/2
      }}>
        <Number
          n={currentValue}
          gridSize={gridSize}
          margin={margin}

        />
      </motion.g>
      <motion.g initial={{x: p3}} animate={{
        x: p3,
        scale: [1, 1.05, 1, 1, .9, 1, 1, .9, 1, 1, 1.05, 1, 1],
        transition: transitionPlusMinus,
        y: margin/2
      }}>
        <Number
          n={currentValue}
          gridSize={gridSize}
          margin={margin}

        />
      </motion.g>
    </svg>
  </svg> 
}


const InfoPopup = ({closePopup}) => {
  const ref = useRef(null)
  const [viewHeight, setViewHeight] = useState(0)

  useEffect(() => {
    const updateDimensions = () => {
      if (ref.current) {
        setViewHeight(ref.current.clientHeight)
      }
    };

    // Call the updateDimensions function initially and on each update
    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);


  const containerStyle = {
    height: '100%', 
    width: '100%', 
    backdropFilter: 'blur(8px)',
    paddingTop: '5%',
    backgroundColor: 'rgba(255, 255, 255)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }

  const xButtonStyle = {
    position: 'absolute',
    height: '8%',
    width: '8%',
    top: '3%',
    right: '3%',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
  };

  const containerTopBodyStyle = {
    fontFamily: 'Nunito',
    textAlign: 'center',
    // width: '92%',
    padding: '10%',
    paddingTop: '5%',
    paddingBottom: '2%',
    fontSize: `${viewHeight * .037}px`,
    color: '#314f4d'
  }

  const containerBodyStyle = {
    fontFamily: 'Nunito',
    textAlign: 'center',
    // width: '92%',
    padding: '2%',
    paddingBottom: '1%',
    fontSize: `${viewHeight * .037}px`,
    color: '#314f4d'
  }

  const letsGoButtonStyle = {
    display: "inline-block",
    fontFamily: 'Nunito',
    fontSize: `${viewHeight * .04}px`,
    marginTop: "3%",
    padding: "2% 4%",
    backgroundColor: "#569FA9",
    color: "white",
    borderRadius: "5px",
    cursor: "pointer"
  }

  return <div ref={ref} style={containerStyle}>
    {/* <div style={containerTopBodyStyle}>
      {'Align all nine numbers by incrementing and rotating with as few moves as possible.'}
    </div> */}
    <div style={containerTopBodyStyle}>
      {'Solve the daily puzzle by making all nine squares contain the same number.'}
    </div>
    <div style={containerBodyStyle}>
      {'Green triangles add and subtract by 1.'}
    </div>
    <PlusMinusDemo/>
    <div style={containerBodyStyle}>
      {'Blue arrows rotate.'}
    </div>
    <ChevronDemo/>
    <img 
      style={xButtonStyle}
      src={process.env.PUBLIC_URL + '/svgs/close.svg'}
      onClick={closePopup}
      id='closePopup'
    />
    <motion.div 
      style={letsGoButtonStyle}
      whileHover={{ scale: 1.12, backgroundColor: "#314f4d" }}
      onClick={closePopup}
    >
      Let's go!
    </motion.div>
  </div>
}

export default InfoPopup;


