import React, { useState, useEffect, useRef } from 'react'
import { AnimatePresence, motion, wrap } from "framer-motion"
import { copyStringToClipboard, convertMovesToEmojis } from '../logic/ShareStrings'

const textStyle = {
  userSelect: 'none',
  WebkitUserSelect: 'none',
  MozUserSelect: 'none',
  msUserSelect: 'none',
  background: 'none', 
  pointerEvents: 'none'
};

const colors = ["#4A837F","#569278","#8AB29A", "#A1CEA0","#C5DBB8", "#B7E8C5","#A8DFD2","#8DC7B9","#569FA9"]

const boardAnimations = {
  gameStartTwice: ({index, onComplete}) => ({
    scaleY: [1, .9, 1, .9, 1],
    fill: "#569FA9",
    transition: { 
      delay: .05, 
      times: [0, .25, .5, .75, 1], 
      duration: 1.5, 
      ease: 'easeIn',
      onComplete: onComplete
    }
  }),
  gameStartOnce: ({index, onComplete}) => ({
    scale: [1, 1.06, 1],
    transition: { 
      delay: index * .05, 
      times: [0, .5, 1], 
      duration: .7, 
      ease: 'backOut',
      onComplete: onComplete
    }
  }),
  gameStartShrink: ({index, onComplete}) => ({
    scale: [1, .8, 1],
    transition: { 
      delay: index * .05, 
      times: [0, .5, 1], 
      duration: .7, 
      ease: 'backOut',
      onComplete: onComplete
    }
  }),
  gameStartShrinkLess: ({index, onComplete}) => ({
    scale: [1, .9, 1],
    transition: { 
      delay: index * .05, 
      times: [0, .5, 1], 
      duration: .7, 
      ease: 'backOut',
      onComplete: onComplete
    }
  }),
  normal: {
    scale: 1,
    fill: "rgb(86, 159, 169, 1)"
  }
}

function polygonToPath(points) {
  const pointArray = points.split("\n");
  const pathCommands = [];

  // Start the path at the first point
  const [startX, startY] = pointArray[0].split(",");

  pathCommands.push(`M${startX},${startY}`);

  // Iterate over the remaining points
  for (let i = 1; i < pointArray.length; i++) {
    const [x, y] = pointArray[i].split(",");
    pathCommands.push(`L${x},${y}`);
  }

  // Close the path
  pathCommands.push("Z");

  return pathCommands.join(" ");
}

const Diamond = ({bottomX, bottomY, diamondW}) => {
  const ornamentSq1 = {
    fill: "#A1CEA0",
    strokeWidth:0 ,
    originX: `${bottomX}px`,
    originY: `${bottomY + diamondW / 2}px`,
  }

  return <motion.polygon 
    points={`
      ${bottomX},${bottomY}
      ${bottomX + diamondW/2},${bottomY + diamondW / 2},
      ${bottomX},${bottomY + diamondW}
      ${bottomX - diamondW/2},${bottomY + diamondW / 2}`}
    style={ornamentSq1}
  /> 
}

const HalfDiamondSideways = ({bottomX, bottomY, diamondW, isBackwards = false}) => {
  const ornamentSq1 = {
    fill: "#A1CEA0",
    strokeWidth:0 ,
    originX: `${bottomX}px`,
    originY: `${bottomY + diamondW / 2}px`,
  }

  const thirdPointX = isBackwards ? bottomX - diamondW/2 : bottomX + diamondW / 2
  const triangle = [[bottomX, bottomY], [bottomX, bottomY + diamondW], [thirdPointX, bottomY + diamondW / 2]]

  return <g>
    {triangleFromPoints(triangle, ornamentSq1)}
  </g>
}

const HalfDiamondPointDown = ({bottomX, bottomY, width, margin}) => {
  const ornamentSq1 = {
    fill: "#8DC7B9",
    strokeWidth:0 ,
    originX: `${bottomX}px`,
    originY: `${bottomY + width / 2}px`,
  }

  const v1 = [bottomX, bottomY]
  const v2 = [bottomX + width/2, bottomY + width / 2]
  const v3 = [bottomX - width/2, bottomY + width / 2]
  const slightlySmallerMgn = marginDetails(margin.margin * .75)

  const [triangle1, triangle2] = splitRightTriangle([v1, v2, v3], slightlySmallerMgn)
  const [small1, small2] = splitRightTriangle(triangle1, slightlySmallerMgn)
  const [small3, small4] = splitRightTriangle(triangle2, slightlySmallerMgn)

  return <g>
    {/* {triangleFromPoints([v1, v2, v3], ornamentSq1)} */}
    {triangleFromPoints(triangle1, ornamentSq1)}
    {triangleFromPoints(triangle2, ornamentSq1)}
    {/* {triangleFromPoints(small1, ornamentSq1)}
    {triangleFromPoints(small2, ornamentSq1)}
    {triangleFromPoints(small3, ornamentSq1)}
    {triangleFromPoints(small4, ornamentSq1)} */}
  </g>
}

const BorderRect = ({x, y, h, w}) => {
  const borderRect = {
    fill: "#569FA9",
    strokeWidth:0 ,
  }

  return <polygon 
    points={`
      ${x},${y}
      ${x},${y + h},
      ${x + w},${y + h},
      ${x + w},${y}
    `}
    style={borderRect}
  /> 
}

const HalfDiamondForSmallDisp = ({bottomX, bottomY, width, margin}) => {
  const ornamentSq1 = {
    fill: "#8DC7B9",
    strokeWidth:0 ,
    originX: `${bottomX}px`,
    originY: `${bottomY + width / 2}px`,
  }

  const v1 = [bottomX, bottomY]
  const v2 = [bottomX + width/2, bottomY + width / 2]
  const v3 = [bottomX - width/2, bottomY + width / 2]
  const slightlySmallerMgn = marginDetails(margin.margin * .75)

  const [triangle1, triangle2] = splitRightTriangle([v1, v2, v3], slightlySmallerMgn)
  const [small1, small2] = splitRightTriangle(triangle1, slightlySmallerMgn)
  const [small3, small4] = splitRightTriangle(triangle2, slightlySmallerMgn)

  return <g>
    {triangleFromPoints([v1, v2, v3], ornamentSq1)}
    {/* {triangleFromPoints(triangle1, ornamentSq1)}
    {triangleFromPoints(triangle2, ornamentSq1)} */}
    {/* {triangleFromPoints(small1, ornamentSq1)}
    {triangleFromPoints(small2, ornamentSq1)}
    {triangleFromPoints(small3, ornamentSq1)}
    {triangleFromPoints(small4, ornamentSq1)} */}
  </g>
}

const ButtonTriangle = ({onClick, x, y, triangleW, direction, rotateText=0, animations, index, id}) => {
  const [isHovered, setIsHovered] = useState(false);

  const buttonAnimationEnabled = animations.animationSettings.animateButtons

  const handleMouseEnter = () => {
    if (!animations.movesDisabled) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  
  const icon = direction == -1 ? `-` : `+`
  const triangleButtonStyle = {
    stroke: '#A1CEA0',
    strokeLinejoin: "round",
    fillRule:"nonzero",
    cursor: "pointer",
    originX: `${x + triangleW / 2}px`,
    originY: `${y + triangleW / 4}px`,
  }

  const noFillStyle = {
    cursor: "pointer",
  }

  const emptyStyle = {
    cursor: "pointer",
    fill:"#ffffff",
  }

  const trianlgePoints = `${x},${y}
    ${x + triangleW},${y}
    ${x + triangleW / 2},${y + triangleW / 2}`

  const tinyMargin = marginDetails(triangleW * .027)
  const zeroMargin = marginDetails(0)

  // W/O margins
  const mainTriangle = [[x + triangleW / 2, y + triangleW/2], [x + triangleW, y], [x, y]]
  var nonMargined = splitRightTriangle(mainTriangle, zeroMargin)
  var smaller = []
  nonMargined.map((tri, idx) => {
    smaller.push(...splitRightTriangle(tri, zeroMargin))
  })
  var evenSmaller = []
  smaller.map((tri, idx) => {
    evenSmaller.push(...splitRightTriangle(tri, zeroMargin))
  })

  // W/ margin 
  var smallMargined = splitRightTriangle(mainTriangle, tinyMargin)
  var smallerMargined = []
  smallMargined.map((tri, idx) => {
    smallerMargined.push(...splitRightTriangle(tri, tinyMargin))
  })
  var evenSmallerMargined = []
  smallerMargined.map((tri, idx) => {
    evenSmallerMargined.push(...splitRightTriangle(tri, tinyMargin))
  })

  return <g
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
    onClick={onClick}
    id={id}
  >
    <motion.g
      custom={{
        index: index,
        onComplete: animations.animatingGameStart ? animations.doneAnimatingGameStart : animations.doneAnimatingGameWin
      }}
      animate={(animations.animatingGameStart || animations.animatingGameWin) ? "gameStartShrink" : "normal"}
      whileTap={{ scale: 0.93 }}
      variants={boardAnimations}
    >
      <AnimatePresence>
        {(isHovered && buttonAnimationEnabled) && 
          <motion.path 
            key='solidTringle'
            fill="#ffffff"
            d={polygonToPath(trianlgePoints)}
            style={emptyStyle}
          />
        }
        {(isHovered && buttonAnimationEnabled) && 
          evenSmallerMargined.map((tri, idx) => {
            const fill = colors[Math.floor(Math.random() * 9)]
            const style = {
              ...noFillStyle, 
              fill: null
            }
            return animateTriangleFromPoints(tri, fill, noFillStyle, idx, 0, '#ffffff')
          })
        }
        {(!isHovered || !buttonAnimationEnabled)&&
          evenSmaller.map((tri, idx) => {
            return animateTriangleFromPoints(tri, "#A1CEA0", triangleButtonStyle, idx, 1, "#A1CEA0")
          })
        }
        {(!isHovered || !buttonAnimationEnabled) && 
          <g transform={`rotate(${rotateText} ${x + triangleW/2} ${y + triangleW * .19})`}>
            <text style={textStyle} x={x + triangleW/2} y={y + triangleW * .19} fontSize={`${Math.floor(triangleW/2)}px`} fontFamily='ProtoMono-Regular' textAnchor='middle' dominantBaseline={'middle'} fill='#ffffff'>
              {icon}
            </text>
          </g> 
        }
      </AnimatePresence>
    </motion.g>
  </g> 
}

const ChevronArrow = ({onClick, x, y, width, gameBoardSize, animations, index, id}) => {
  const [isHovered, setIsHovered] = useState(false);

  const buttonAnimationEnabled = animations.animationSettings.animateButtons

  const handleMouseEnter = () => {
    if (!animations.movesDisabled) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const arrowStyle = {
    originX: `${x + width / 2}px`,
    originY: `${y + width * .8}px`,
    cursor: "pointer",
    stroke:"#569FA9",
    strokeLinejoin: "round"
  }

  const noFillStyle = {
    cursor: "pointer",
  }

  const emptyStyle = {
    cursor: "pointer",
    fill:"#ffffff",
  }

  const chevronPoints = `${x + width / 2},${y + width}
    ${x + width},${y + width / 2},
    ${x + width},${y},
    ${x + width / 2},${y + width / 2}
    ${x},${y} 
    ${x},${y + width / 2}`

  const tinyMargin = marginDetails(width * .027)
  const zeroMargin = marginDetails(0)

  // W/O margins
  const leftTriangle = [[x, y + width / 2], [x, y], [x + width/2, y + width/2]]
  const rightTriangle = [[x + width/2, y + width/2], [x + width, y],[x + width, y + width/2]]
  const topBiggerTriangle = [[x + width/2, y + width], [x, y + width / 2], [x + width, y + width/2]]
  const [upper1NoMgn, upper2NoMgn] = splitRightTriangle(topBiggerTriangle, zeroMargin)
  var nonMargined = []
  nonMargined.push(...splitRightTriangle(leftTriangle, zeroMargin))
  nonMargined.push(...splitRightTriangle(rightTriangle, zeroMargin))
  nonMargined.push(...splitRightTriangle(upper1NoMgn, zeroMargin))
  nonMargined.push(...splitRightTriangle(upper2NoMgn, zeroMargin))
  var evenSmallerNoMargin = []
  nonMargined = nonMargined.map((tri, idx) => {
    evenSmallerNoMargin.push(...splitRightTriangle(tri, zeroMargin))
  })

  // W/ margin 
  const leftTriangleMgn = [[x, y + width / 2 - tinyMargin.half], [x, y], [x + width/2 - tinyMargin.longDiagonal, y + width/2 - tinyMargin.half]]
  const rightTriangleMgn = [[x + width/2 + tinyMargin.longDiagonal, y + width/2 - tinyMargin.half], [x + width, y], [x + width, y + width/2 - tinyMargin.half]]
  const topBiggerTriangleMgn = [[x + width/2, y + width], [x + tinyMargin.half, y + width / 2 + tinyMargin.half], [x + width - tinyMargin.half, y + width/2 + tinyMargin.half]]
  const [upper1, upper2] = splitRightTriangle(topBiggerTriangleMgn, tinyMargin)
  var marginedTriangles = []
  marginedTriangles.push(...splitRightTriangle(leftTriangleMgn, tinyMargin))
  marginedTriangles.push(...splitRightTriangle(rightTriangleMgn, tinyMargin))
  marginedTriangles.push(...splitRightTriangle(upper1, tinyMargin))
  marginedTriangles.push(...splitRightTriangle(upper2, tinyMargin))

  var evenSmallerMgnd = []
  marginedTriangles = marginedTriangles.map((tri, idx) => {
    evenSmallerMgnd.push(...splitRightTriangle(tri, tinyMargin))
  })
  // const marginedTriangles = [t1, t2, t3, t4]


  // const extended = `${x},${y - gameBoardSize} 
  // ${x},${y + width / 2}
  // ${x + width / 2},${y + width}
  // ${x + width},${y + width / 2},
  // ${x + width},${y - gameBoardSize},
  // ${x + width / 2},${y - gameBoardSize}`

  // const extended = `${x},${y} 
  //   ${x},${y + width}
  //   ${x + width / 2},${y + width * 1.5}
  //   ${x + width},${y + width},
  //   ${x + width},${y},
  //   ${x + width / 2},${y + width / 2}`

  return <g
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
    onClick={onClick}
  >
    <motion.g
      custom={{
        index: index,
        onComplete: animations.animatingGameStart ? animations.doneAnimatingGameStart : animations.doneAnimatingGameWin
      }}
      animate={(animations.animatingGameStart || animations.animatingGameWin) ? "gameStartTwice" : "normal"}
      whileTap={{ scale: 0.95 }}
      variants={boardAnimations}
      id={id}
    >
      <AnimatePresence>
        {(isHovered && buttonAnimationEnabled) && 
          <motion.path 
            key='solidArrowForHover'
            animate={{fill: 'rgb(255, 255, 255, 0)'}}
            d={polygonToPath(chevronPoints)}
            style={noFillStyle}
          />
        }
        
        {(isHovered && buttonAnimationEnabled) &&
          evenSmallerMgnd.map((tri, idx) => {
            const fill = colors[Math.floor(Math.random() * 9)]
            const style = {
              ...noFillStyle, 
              fill: "#ffffff"
            }
            return animateTriangleFromPoints(tri, fill, noFillStyle, idx, 0, '#ffffff')
          })
        }
        {(!isHovered || !buttonAnimationEnabled) &&
          evenSmallerNoMargin.map((tri, idx) => {
            return animateTriangleFromPoints(tri, "#569FA9", arrowStyle, idx, 1, "#569FA9")
          })
        }
      </AnimatePresence>
    </motion.g>
  </g>
}

const BigIncrementButton = ({onClick, x, y, triangleW, buttonH, direction, rotateText=0, animations, index}) => {
  
  const icon = direction == -1 ? `-` : `+`
  
  const trianlgePoints = `${x},${y}
    ${x},${y + buttonH - triangleW / 2}
    ${x + triangleW/2},${y + buttonH}
    ${x + triangleW},${y + buttonH - triangleW / 2}
    ${x + triangleW},${y}`


  const tinyMargin = marginDetails(triangleW * .027)
  const zeroMargin = marginDetails(0)

  // W/O margins
  const mainTriangle = [[x + triangleW / 2, y + triangleW/2], [x + triangleW, y], [x, y]]
  var nonMargined = splitRightTriangle(mainTriangle, zeroMargin)
  var smaller = []
  nonMargined.map((tri, idx) => {
    smaller.push(...splitRightTriangle(tri, zeroMargin))
  })
  var evenSmaller = []
  smaller.map((tri, idx) => {
    evenSmaller.push(...splitRightTriangle(tri, zeroMargin))
  })

  // W/ margin 
  var smallMargined = splitRightTriangle(mainTriangle, tinyMargin)
  var smallerMargined = []
  smallMargined.map((tri, idx) => {
    smallerMargined.push(...splitRightTriangle(tri, tinyMargin))
  })
  var evenSmallerMargined = []
  smallerMargined.map((tri, idx) => {
    evenSmallerMargined.push(...splitRightTriangle(tri, tinyMargin))
  })

  return <g
    onClick={onClick}
  >
    <motion.g
      custom={{
        index: index,
        onComplete: animations.animatingGameStart ? animations.doneAnimatingGameStart : animations.doneAnimatingGameWin
      }}
      animate={(animations.animatingGameStart || animations.animatingGameWin) ? "gameStartShrinkLess" : "normal"}
      whileTap={{ scale: 0.93 }}
      variants={boardAnimations}
    >
      <motion.path 
        key='solidTringle'
        fill="#A1CEA0"
        d={polygonToPath(trianlgePoints)}
      />
      <g transform={`rotate(${rotateText} ${x + triangleW/2} ${y + buttonH * .35})`}>
        <text style={textStyle} x={x + triangleW/2} y={y + buttonH * .35} fontSize={`${Math.floor(triangleW/2)}px`} fontFamily='ProtoMono-Regular' textAnchor='middle' dominantBaseline={'middle'} fill='#ffffff'>
          {icon}
        </text>
      </g> 
    </motion.g>
  </g> 
}

const BigChevronArrow = ({onClick, x, y, width, buttonH, gameBoardSize, animations, index}) => {
  const [isHovered, setIsHovered] = useState(false);

  const buttonAnimationEnabled = animations.animationSettings.animateButtons

  const handleMouseEnter = () => {
    if (!animations.movesDisabled) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const arrowStyle = {
    originX: `${x + width / 2}px`,
    originY: `${y + buttonH * .8}px`,
    cursor: "pointer",
    fill:"#569FA9",
    // stroke:"#1013A9",
    strokeLinejoin: "round"
  }

  const noFillStyle = {
    cursor: "pointer",
  }

  const emptyStyle = {
    cursor: "pointer",
    fill:"#ffffff",
  }

  const chevronPoints = `${x + width / 2},${y + buttonH + width / 2}
    ${x + width},${y + buttonH},
    ${x + width},${y},
    ${x + width / 2},${y + width / 2}
    ${x},${y} 
    ${x},${y + buttonH}`

  const tinyMargin = marginDetails(width * .027)
  const zeroMargin = marginDetails(0)

  return <g
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
    onClick={onClick}
  >
    <motion.g
      custom={{
        index: index,
        onComplete: animations.animatingGameStart ? animations.doneAnimatingGameStart : animations.doneAnimatingGameWin
      }}
      animate={(animations.animatingGameStart || animations.animatingGameWin) ? "gameStartTwice" : "normal"}
      whileTap={{ scale: 0.95 }}
      variants={boardAnimations}
    >
      <motion.path 
        key='solidArrowForHover'
        d={polygonToPath(chevronPoints)}
        style={arrowStyle}
      />
    </motion.g>
  </g>
}

const cornerStyle = {
  fill: "#569FA9",
}

const CornerTrianglesOfSquare = ({x, y, w, h, triangleHeight}) => {

  return <g transform={`translate(${x + w/2} ${y + h/2})`}> 
    {rotations.map((rotation, index) => (
      <g transform={`
      rotate(${rotation})`}
      key={index}
      >
        <motion.polygon
          points = {
            `${w/2},${h/2}
            ${w/2},${h/2 - triangleHeight}
            ${w/2 - triangleHeight},${h/2}`
          }
          style={cornerStyle}
        />
      </g>
    ))
  }
  </g>
}

const AlignTitle = ({x, y, w, h, margin, cornerTriangleHeight, isArchive}) => {
  const titleSquare = {
    fill: "#B7E8C5",
  }

  // const trapezoids = rotations.map((rotation, index) => {
  //   const points = [
  //     [0, h/2]
  //   ]
  // })

  const trapezoidMgn = marginDetails(margin.margin * .75)
  const trapezoidBigSideL = calculateSideLength([margin.longDiagonal / 2,h/2], [w/2, margin.longDiagonal / 2])
  const trapezoidH = Math.sqrt(Math.pow(calculateSideLength([margin.longDiagonal / 2,h/2], [w/2 - cornerTriangleHeight - margin.longDiagonal, h/2]), 2) / 2)
  const numTriangles = 3
  const triangleW = (trapezoidBigSideL - (trapezoidMgn.longDiagonal * (numTriangles - 1))) / numTriangles

  const smolTris = []
  var curX = 0
  for (let i = 0; i < numTriangles; i++) {
    smolTris.push([[curX, 0], [curX + triangleW/2, triangleW/2], [curX + triangleW/2 * 2, 0]])
    curX = curX + triangleW + trapezoidMgn.longDiagonal
  }

  curX = 0
  for (let i = 0; i < numTriangles - 1; i++) {
    smolTris.push([
      [curX + triangleW/2 + trapezoidMgn.longDiagonal/2, trapezoidH], 
      [curX + triangleW/2 * 2 + trapezoidMgn.longDiagonal/2, trapezoidH - triangleW/2], 
      [curX + triangleW/2 * 3 + trapezoidMgn.longDiagonal/2, trapezoidH]
    ])
    curX = curX + triangleW + trapezoidMgn.longDiagonal
  }
  return <g transform={`translate(${x + w/2} ${y + h/2})`}> 
    {rotations.map((rotation, index) => (
      <g transform={`
        rotate(${rotation})`}
        key={index}
      >
        {/* <motion.polygon
          points = {
            `${margin.shortDiagonal},${h/2}
            ${w/2 - cornerTriangleHeight - margin.longDiagonal},${h/2}
            ${w/2},${h/2 - cornerTriangleHeight - margin.longDiagonal}
            ${w/2},${margin.shortDiagonal}`
          }
          style={titleSquare}
        /> */}
        <g transform={
          `translate(${margin.longDiagonal / 2} ${h/2}) rotate(-45)`
        }>
          {
            smolTris.map((smolTri, index) => {
              const style = {
                fill: colors[Math.floor(Math.random() * 9)]
              }
              return <g key={index}>
                {triangleFromPoints(smolTri, style)}
              </g>
            })
          }
        </g>
      </g>
    ))
  }
    <motion.polygon
      points = {
        `${0},${h/2 - margin.shortDiagonal}
        ${w/2 - margin.shortDiagonal},${0}
        ${0},${-1 * (h/2 - margin.shortDiagonal)}
        ${-1 * (w/2 - margin.shortDiagonal)},${0}`
      }
      style={titleSquare}
    />
    {
      isArchive && 
      <g>
        <text x={0} y={-5} fontSize={`${Math.floor(h/6)}px`} fontFamily='ProtoMono-Regular' textAnchor='middle' dominantBaseline={'middle'} fill='#314f4d' style={textStyle}>
          ali9n
        </text>
        <text x={0} y={30} fontSize={`${Math.floor(h/10)}px`} fontFamily='ProtoMono-Regular' textAnchor='middle' dominantBaseline={'middle'} fill='#314f4d' style={textStyle}>
          archive
        </text>
      </g>
    }
    {
      !isArchive && 
      <text x={0} y={0} fontSize={`${Math.floor(h/6)}px`} fontFamily='ProtoMono-Regular' textAnchor='middle' dominantBaseline={'middle'} fill='#314f4d' style={textStyle}>
        ali9n
      </text>
    }
  </g>
}

const CurrentScore = ({x, y, w, h, margin, cornerTriangleHeight, score}) => {
  const scoreSideTrapezoids = {
    fill: "#A1CEA0",
  }

  const scoreSquare = {
    fill: "#B7E8C5",
  }

  return <g transform={`translate(${x + w/2} ${y + h/2})`}> 
    {rotations.map((rotation, index) => (
      <g transform={`
        rotate(${rotation})`}
        key={index}
      >
        <motion.polygon
          points = {
            `${margin.shortDiagonal},${h/2}
            ${w/2 - cornerTriangleHeight - margin.longDiagonal},${h/2}
            ${w/2},${h/2 - cornerTriangleHeight - margin.longDiagonal}
            ${w/2},${margin.shortDiagonal}`
          }
          style={scoreSideTrapezoids}
        />
      </g>
    ))
  }
    <motion.polygon
      points = {
        `${0},${h/2 - margin.shortDiagonal}
        ${w/2 - margin.shortDiagonal},${0}
        ${0},${-1 * (h/2 - margin.shortDiagonal)}
        ${-1 * (w/2 - margin.shortDiagonal)},${0}`
      }
      style={scoreSquare}
    />
    <text x={0} y={0} fontSize={`${Math.floor(h/3)}px`} fontFamily='ProtoMono-Regular' textAnchor='middle' dominantBaseline={'middle'} fill='#314f4d' style={textStyle}>
      {score}
    </text>
  </g>
}

const BestScoreToday = ({x, y, w, h, margin, cornerTriangleHeight, scoreInfo, bestScore, bestMoves, gameNumber, isArchive}) => {
  const scoreSideTrapezoids = {
    fill: "#A1CEA0",
  }

  const scoreSquare = {
    fill: "#B7E8C5",
  }

  const trapezoidMgn = marginDetails(margin.margin * .75)
  const trapezoidBigSideL = calculateSideLength([margin.longDiagonal / 2,h/2], [w/2, margin.longDiagonal / 2])
  const trapezoidH = Math.sqrt(Math.pow(calculateSideLength([margin.longDiagonal / 2,h/2], [w/2 - cornerTriangleHeight - margin.longDiagonal, h/2]), 2) / 2)
  const numTriangles = 3
  const triangleW = (trapezoidBigSideL - (trapezoidMgn.longDiagonal * (numTriangles - 1))) / numTriangles
  const colors = ["#4A837F","#569278","#8AB29A", "#A1CEA0","#C5DBB8", "#B7E8C5","#A8DFD2","#8DC7B9","#569FA9"]
  const [copiedSuccess, setCopiedSuccess] = useState(false)

  const smolTris = []
  var curX = 0
  for (let i = 0; i < numTriangles; i++) {
    smolTris.push([[curX, 0], [curX + triangleW/2, triangleW/2], [curX + triangleW/2 * 2, 0]])
    curX = curX + triangleW + trapezoidMgn.longDiagonal
  }

  curX = 0
  for (let i = 0; i < numTriangles - 1; i++) {
    smolTris.push([
      [curX + triangleW/2 + trapezoidMgn.longDiagonal/2, trapezoidH], 
      [curX + triangleW/2 * 2 + trapezoidMgn.longDiagonal/2, trapezoidH - triangleW/2], 
      [curX + triangleW/2 * 3 + trapezoidMgn.longDiagonal/2, trapezoidH]
    ])
    curX = curX + triangleW + trapezoidMgn.longDiagonal
  }

  const copyScore = () => {
    if (bestScore != '-') {
      copyStringToClipboard(convertMovesToEmojis(bestScore, bestMoves, gameNumber, scoreInfo.bestGameAlignedNumber))
      setCopiedSuccess(true)
      setTimeout(() => {
        setCopiedSuccess(false);
      }, 1500);
    }
  }

  return <g transform={`translate(${x + w/2} ${y + h/2})`}> 
    {rotations.map((rotation, index) => (
      <g transform={`
        rotate(${rotation})`}
        key={index}
      >
        <motion.polygon
          points = {
            `${margin.shortDiagonal},${h/2}
            ${w/2 - cornerTriangleHeight - margin.longDiagonal},${h/2}
            ${w/2},${h/2 - cornerTriangleHeight - margin.longDiagonal}
            ${w/2},${margin.shortDiagonal}`
          }
          style={scoreSideTrapezoids}
        />
        {/* <g transform={
          `translate(${margin.longDiagonal / 2} ${h/2}) rotate(-45)`
        }>
          {
            smolTris.map((smolTri, index) => {
              const style = {
                fill: colors[Math.floor(Math.random() * 9)]
              }
              return <g key={index}>
                {triangleFromPoints(smolTri, style)}
              </g>
            })
          }
        </g> */}
      </g>
    ))
  }
    <motion.polygon
      points = {
        `${0},${h/2 - margin.shortDiagonal}
        ${w/2 - margin.shortDiagonal},${0}
        ${0},${-1 * (h/2 - margin.shortDiagonal)}
        ${-1 * (w/2 - margin.shortDiagonal)},${0}`
      }
      style={{
        cursor: 'pointer'
      }}
      animate={scoreSquare}
      whileHover={{
        scale: 1.05,
        transition: {
          duration: .3,
          ease: 'backOut'
        },
        fill: "#A8DFD2"
      }}
      onClick={() => copyScore()}
    />
    {
      !copiedSuccess && 
      <motion.g
        initial={{
          opacity: 0
        }}
        animate={{
          opacity: 1
        }}
      >
        { !isArchive && 
          <g>
          <text x={0} y={-2 * h/10} fontSize={`${Math.floor(h/12)}px`} fontFamily='ProtoMono-Regular' textAnchor='middle' dominantBaseline={'middle'} fill='#314f4d' style={textStyle}>
            Your
          </text>
          <text x={0} y={-1 * h/10} fontSize={`${Math.floor(h/12)}px`} fontFamily='ProtoMono-Regular' textAnchor='middle' dominantBaseline={'middle'} fill='#314f4d' style={textStyle}>
            Best Today
          </text>
          </g>
        }
        { isArchive &&
          <g>
            <text x={0} y={-1.3 * h/10} fontSize={`${Math.floor(h/12)}px`} fontFamily='ProtoMono-Regular' textAnchor='middle' dominantBaseline={'middle'} fill='#314f4d' style={textStyle}>
              Your Best
            </text>
          </g>
        }
        <text x={0} y={h/10} fontSize={`${Math.floor(h/5)}px`} fontFamily='ProtoMono-Regular' textAnchor='middle' dominantBaseline={'middle'} fill='#314f4d' style={textStyle}>
          {bestScore}
        </text>
        {
          (bestScore != '-') && 
          <image pointerEvents='none' xlinkHref={process.env.PUBLIC_URL + '/svgs/share.svg'} x={-18} y={h/3.5 -18} width={36} height={36}/>
        }
      </motion.g>
    }
    {
      copiedSuccess && 
      <AnimatePresence>
        <motion.g
          initial={{
            opacity: 0
          }}
          animate={{
            opacity: 1
          }}
        >
          <text x={0} y={-1 * h/17} fontSize={`${Math.floor(h/12)}px`} fontFamily='ProtoMono-Regular' textAnchor='middle' dominantBaseline={'middle'} fill='#314f4d' style={textStyle}>
            Copied to
          </text>
          <text x={0} y={h/17} fontSize={`${Math.floor(h/12)}px`} fontFamily='ProtoMono-Regular' textAnchor='middle' dominantBaseline={'middle'} fill='#314f4d' style={textStyle}>
            clipboard
          </text>
        </motion.g>
      </AnimatePresence>
      
    }
  </g>
}

const ResetEtc = ({x, y, w, h, margin, cornerTriangleHeight, buttonFunctions}) => {
  const actionButton = {
    cursor: "pointer",
  }

  return <g transform={`translate(${x + w/2} ${y + h/2})`}> 
    {rotations.map((rotation, index) => {
      var icon;
      var onClick;
      if (index == 2) {
        icon =  process.env.PUBLIC_URL + '/svgs/info.svg'
        onClick = buttonFunctions.openInfoPopup
      } else if (index == 1) {
        icon = process.env.PUBLIC_URL + '/svgs/bar-chart.svg';
        onClick = buttonFunctions.openStatsPopup
      } else if (index == 0) {
        icon = process.env.PUBLIC_URL + '/svgs/refresh-reverse.svg';
        onClick = buttonFunctions.restart
      } else {
        icon = process.env.PUBLIC_URL + '/svgs/cog.svg';
        onClick = buttonFunctions.openSettingsPopup
      }
      const polyGonDiamondPoints = `${margin.half},${h/2}
         ${w/2 - cornerTriangleHeight - margin.longDiagonal},${h/2}
         ${w/2},${h/2 - cornerTriangleHeight - margin.longDiagonal}
         ${w/2},${margin.half}
         ${margin.half},${margin.half}`

      const polyGonSquarePoints = `${margin.half},${h/2}
      ${w/2 - margin.half},${h/2}
      ${w/2},${h/2}
      ${w/2},${margin.half}
      ${margin.half},${margin.half}`
      
      const variants = {
        diamond: {
          path: polygonToPath(polyGonDiamondPoints),
          fill: "#B7E8C5"
        }, 
        square: {
          scale: 1.05,
          d: polygonToPath(polyGonDiamondPoints),
          transition: {
            duration: .3,
            ease: 'backOut'
          },
          fill: "#A8DFD2"
        }
      }

      return <g transform={`
        rotate(${rotation + 45})`}
        key={index}
      >
        <motion.path
          style={actionButton}
          fill="#B7E8C5"
          d={variants.diamond.path}
          whileHover="square"
          variants={variants}
          onClick={onClick}
        />
        <g transform={`translate(${h * .23} ${h * .23}) rotate(-${rotation + 45})`}>
            <image pointerEvents='none' xlinkHref={icon} x={-18} y={-18} width={36} height={36}/>
        </g>
      </g>
    })
  }
  </g>
}

function marginDetails(margin) {
  return { 
    margin: margin, 
    half: margin/2,
    longDiagonal: Math.sqrt(Math.pow(margin,2) * 2),
    shortDiagonal: Math.sqrt(Math.pow(margin,2) / 2)
  }
}

function splitRightTriangle(triangle, margin) {
  const orderedTri = reorderTrianglePoints(triangle)
  return [
    [orderedTri[1], getPointAlongLine(orderedTri[0], orderedTri[1], margin.shortDiagonal), getAdjustedHalfwayPoint(orderedTri[1], orderedTri[2], margin.margin)],
    [orderedTri[2], getPointAlongLine(orderedTri[0], orderedTri[2], margin.shortDiagonal), getAdjustedHalfwayPoint(orderedTri[2], orderedTri[1], margin.margin)]
  ]
}

function triangleFromPoints(points, style, key) {
  return <motion.polygon 
    key={key}
    animate={{
      transition: {
        duration: .3,
        ease: 'backOut'
      },
    }}
    points={`
      ${points[0][0]},${points[0][1]}
      ${points[1][0]},${points[1][1]}
      ${points[2][0]},${points[2][1]}`}
    style={style}
  />
}

function animateTriangleFromPoints(points, fill, style, key, strokeW, strokeColor) {
  const pointsString = `
    ${points[0][0]},${points[0][1]}
    ${points[1][0]},${points[1][1]}
    ${points[2][0]},${points[2][1]}`
  return <motion.polygon 
    key={key}
    animate={{
      points: pointsString,
      fill: fill,
      strokeWidth: strokeW,
      stroke: strokeColor,
      transition: {
        points: {
          duration: 1,
          ease: 'backOut'
        }, 
        fill: {
          duration: 1
        },
        stroke: {
          duration: 0
        }
      },
    }}
    points={pointsString}
    style={style}
  />
}

function getPointAlongLine(v1, v2, diff) {
  const [x1, y1] = v1;
  const [x2, y2] = v2;
  const dx = x2 - x1;
  const dy = y2 - y1;
  const distance = Math.sqrt(dx * dx + dy * dy);
  const ratio = diff / distance;
  const newX = x1 + dx * ratio;
  const newY = y1 + dy * ratio;
  return [newX, newY];
}

function reorderTrianglePoints(triangle) {
  const [point1, point2, point3] = triangle;

  const point3Side = calculateSideLength(point1, point2);
  const point1Side = calculateSideLength(point2, point3);
  const point2Side = calculateSideLength(point3, point1);

  if (point3Side > point1Side) {
    return [point3, point2, point1]
  } else if (point2Side > point3) {
    return [point2, point1, point3]
  } else {
    return triangle
  }
}

function calculateSideLength(point1, point2) {
  const [x1, y1] = point1;
  const [x2, y2] = point2;

  const dx = x2 - x1;
  const dy = y2 - y1;

  return Math.sqrt(dx * dx + dy * dy);
}


function getAdjustedHalfwayPoint(v1, v2, diff) {
  const [x1, y1] = v1;
  const [x2, y2] = v2;
  const dx = x2 - x1;
  const dy = y2 - y1;

  if (dx === 0 && dy === 0) {
    // Both dx and dy are zero, return v1 as the halfway point
    return v1;
  }

  const halfwayX = x1 + dx / 2;
  const halfwayY = y1 + dy / 2;

  let adjustedX, adjustedY;
  if (dx === 0) {
    // dx is zero, adjust only along y-axis
    adjustedX = halfwayX;
    adjustedY = halfwayY - (dy / 2) * (diff / Math.abs(dy));
  } else if (dy === 0) {
    // dy is zero, adjust only along x-axis
    adjustedX = halfwayX - (dx / 2) * (diff / Math.abs(dx));
    adjustedY = halfwayY;
  } else {
    // Both dx and dy are non-zero, adjust normally
    const distance = Math.sqrt(dx * dx + dy * dy);
    adjustedX = halfwayX - (dx / 2) * (diff / distance);
    adjustedY = halfwayY - (dy / 2) * (diff / distance);
  }

  return [adjustedX, adjustedY];
}

function getButtonCode(index, rowOrCol, isShift) {
  const firstLetter =  (index == 0 || index == 2) ? 'C' : 'R'
  var lastLetter;

  if (isShift) {
    lastLetter = (index == 0 || index == 3) ? ((index == 0 || index == 2) ? 'D' : 'R') : ((index == 0 || index == 2) ? 'U' : 'L')
  } else {
    lastLetter = (index == 0 || index == 1) ? '-' : '+'
  }

  var middleLetter;
  if ((lastLetter == 'R' || lastLetter == 'U' || lastLetter == '+') && rowOrCol == 0) {
    middleLetter = 2
  } else if ((lastLetter == 'R' || lastLetter == 'U' || lastLetter == '+') && rowOrCol == 2) {
    middleLetter = 0
  } else {
    middleLetter = rowOrCol;
  }

  return firstLetter + middleLetter + lastLetter;
}

export const Controls = ({gameBoardSize, wrapperSize, triangleW, nonBorderWrapperH, margin, buttonFunctions, index, animations, isSmallDisplay}) => {
  const triangleOnClick = (index == 0 || index == 2) ? buttonFunctions.incrementColumn : buttonFunctions.incrementRow
  const chevronOnClick = (index == 0 || index == 2) ? buttonFunctions.rotateColumn : buttonFunctions.rotateRow
  const triangleOnClickDirection = (index == 0 || index == 1) ? -1 : 1
  const chevronOnClickDirection = (index == 0 || index == 3) ? 1 : -1
  const offsetChevIndex = (index + 2) % 4

  return <g transform={`
    translate(-${gameBoardSize/2 - margin.half} ${gameBoardSize/2 + margin.half})
  `}>
    {
      !isSmallDisplay && 
      <g>
        <Diamond 
          bottomX={triangleW + margin.half}
          bottomY={triangleW / 2 + margin.margin + margin.shortDiagonal * 2}
          diamondW={triangleW - margin.shortDiagonal / 2}
        />
        <Diamond 
          bottomX={triangleW * 2 + margin.margin + margin.half}
          bottomY={triangleW / 2 + margin.margin + margin.shortDiagonal * 2}
          diamondW={triangleW - margin.shortDiagonal / 2}
        />
        <HalfDiamondSideways 
          bottomX={0}
          bottomY={triangleW / 2 + margin.longDiagonal * 2}
          diamondW={triangleW - margin.shortDiagonal * 2}
        />
        <HalfDiamondSideways 
          bottomX={gameBoardSize - margin.margin}
          bottomY={triangleW / 2 + margin.longDiagonal * 2}
          diamondW={triangleW - margin.shortDiagonal * 2}
          isBackwards={true}
        />
        <HalfDiamondPointDown
          bottomX={triangleW * .5}
          bottomY={margin.longDiagonal * 2 + triangleW } 
          width={triangleW}
          margin={margin}
        />
        <HalfDiamondPointDown
          bottomX={margin.margin + triangleW * 1.5}
          bottomY={margin.longDiagonal * 2 + triangleW } 
          width={triangleW}
          margin={margin}
        />
        <HalfDiamondPointDown
          bottomX={margin.margin * 2 + triangleW * 2.5}
          bottomY={margin.longDiagonal * 2 + triangleW } 
          width={triangleW}
          margin={margin}
        />
        <BorderRect
          x={0} y={nonBorderWrapperH}
          h={wrapperSize - nonBorderWrapperH} w={triangleW}
        />
        <BorderRect
          x={triangleW + margin.margin} y={nonBorderWrapperH}
          h={wrapperSize - nonBorderWrapperH} w={triangleW}
        />
        <BorderRect
          x={(triangleW + margin.margin) * 2} y={nonBorderWrapperH}
          h={wrapperSize - nonBorderWrapperH} w={triangleW}
        />
        <ChevronArrow
          x={0}
          y={margin.longDiagonal} 
          width={triangleW}
          onClick={() => chevronOnClick((index == 2 || index == 3) ? 2 : 0, chevronOnClickDirection)}
          gameBoardSize={gameBoardSize}
          animations={animations}
          index={offsetChevIndex * 3 + 3}
          id={getButtonCode(index, 0, true)}
        />
        <ChevronArrow
          x={margin.margin + triangleW}
          y={margin.longDiagonal} 
          width={triangleW}
          onClick={() => chevronOnClick(1, chevronOnClickDirection)}
          gameBoardSize={gameBoardSize}
          animations={animations}
          index={offsetChevIndex * 3 + 2}
          id={getButtonCode(index, 1, true)}
        />
        <ChevronArrow
          x={margin.margin * 2 + triangleW * 2}
          y={margin.longDiagonal} 
          width={triangleW}
          onClick={() => chevronOnClick((index == 2 || index == 3) ? 0 : 2, chevronOnClickDirection)}
          gameBoardSize={gameBoardSize}
          animations={animations}
          index={offsetChevIndex * 3 + 1}
          id={getButtonCode(index, 2, true)}
        />
        <ButtonTriangle
          x={0}
          y={0}
          triangleW={triangleW}
          onClick={() => triangleOnClick((index == 2 || index == 3) ? 2 : 0, triangleOnClickDirection)}
          direction={triangleOnClickDirection}
          rotateText={(index == 1) ? 90 : 0}
          animations={animations}
          index={index * 3 + 1}
          id={getButtonCode(index, 0, false)}
        />
        <ButtonTriangle
          x={margin.margin + triangleW}
          y={0}
          triangleW={triangleW}
          onClick={() => triangleOnClick(1, triangleOnClickDirection)}
          direction={triangleOnClickDirection}
          rotateText={(index == 1) ? 90 : 0}
          animations={animations}
          index={index * 3 + 1}
          id={getButtonCode(index, 1, false)}
        />
        <ButtonTriangle
          x={margin.margin * 2 + triangleW * 2}
          y={0}
          triangleW={triangleW}
          onClick={() => triangleOnClick((index == 2 || index == 3) ? 0 : 2, triangleOnClickDirection)}
          direction={triangleOnClickDirection}
          rotateText={(index == 1) ? 90 : 0}
          animations={animations}
          index={index * 3 + 1}
          id={getButtonCode(index, 2, false)}
        />
      </g>
    }
    {
      isSmallDisplay && 
      <g>
        <BorderRect
          x={0} y={nonBorderWrapperH}
          h={wrapperSize - nonBorderWrapperH} w={triangleW}
        />
        <BorderRect
          x={triangleW + margin.margin} y={nonBorderWrapperH}
          h={wrapperSize - nonBorderWrapperH} w={triangleW}
        />
        <BorderRect
          x={(triangleW + margin.margin) * 2} y={nonBorderWrapperH}
          h={wrapperSize - nonBorderWrapperH} w={triangleW}
        />
        <HalfDiamondForSmallDisp
          bottomX={triangleW + margin.margin / 2}
          bottomY={nonBorderWrapperH - triangleW / 2 } 
          width={triangleW - margin.margin * 2}
          margin={margin}
        />
        <HalfDiamondForSmallDisp
          bottomX={triangleW * 2 + margin.margin * 1.5}
          bottomY={nonBorderWrapperH - triangleW / 2 } 
          width={triangleW - margin.margin * 2}
          margin={margin}
        />
        <motion.polygon
          points = {
            `${0},${nonBorderWrapperH - margin.margin}
            ${0},${nonBorderWrapperH - triangleW / 2 + margin.longDiagonal - margin.margin}
            ${triangleW / 2 - margin.longDiagonal},${nonBorderWrapperH - margin.margin}`
          }
          fill={'#8DC7B9'}
        />
        <motion.polygon
          points = {
            `${triangleW * 3 + margin.margin * 2},${nonBorderWrapperH - margin.margin}
            ${triangleW * 3 + margin.margin * 2},${nonBorderWrapperH - triangleW / 2 + margin.longDiagonal - margin.margin}
            ${triangleW * 3 + margin.margin * 2 - (triangleW / 2 - margin.longDiagonal)},${nonBorderWrapperH - margin.margin}`
          }
          fill={'#8DC7B9'}
        />
        <BigChevronArrow
          x={0}
          y={margin.longDiagonal + (triangleW / 2 - margin.shortDiagonal)} 
          width={triangleW}
          buttonH={nonBorderWrapperH - triangleW - margin.shortDiagonal - margin.margin}
          onClick={() => chevronOnClick((index == 2 || index == 3) ? 2 : 0, chevronOnClickDirection)}
          gameBoardSize={gameBoardSize}
          animations={animations}
          index={offsetChevIndex * 3 + 3}
        />
        <BigChevronArrow
          x={margin.margin + triangleW}
          y={margin.longDiagonal + (triangleW / 2 - margin.shortDiagonal)} 
          width={triangleW}
          buttonH={nonBorderWrapperH - triangleW - margin.shortDiagonal - margin.margin}
          onClick={() => chevronOnClick(1, chevronOnClickDirection)}
          gameBoardSize={gameBoardSize}
          animations={animations}
          index={offsetChevIndex * 3 + 2}
        />
        <BigChevronArrow
          x={margin.margin * 2 + triangleW * 2}
          y={margin.longDiagonal + (triangleW / 2 - margin.shortDiagonal)} 
          width={triangleW}
          buttonH={nonBorderWrapperH - triangleW - margin.shortDiagonal - margin.margin}
          onClick={() => chevronOnClick((index == 2 || index == 3) ? 0 : 2, chevronOnClickDirection)}
          gameBoardSize={gameBoardSize}
          animations={animations}
          index={offsetChevIndex * 3 + 1}
        />
        <BigIncrementButton
          x={0}
          y={0}
          triangleW={triangleW}
          buttonH={triangleW - margin.shortDiagonal}
          onClick={() => triangleOnClick((index == 2 || index == 3) ? 2 : 0, triangleOnClickDirection)}
          direction={triangleOnClickDirection}
          rotateText={(index == 1) ? 90 : 0}
          animations={animations}
          index={index * 3 + 1}
        />
        <BigIncrementButton
          x={margin.margin + triangleW}
          y={0}
          triangleW={triangleW}
          buttonH={triangleW - margin.shortDiagonal}
          onClick={() => triangleOnClick(1, triangleOnClickDirection)}
          direction={triangleOnClickDirection}
          rotateText={(index == 1) ? 90 : 0}
          animations={animations}
          index={index * 3 + 1}
        />
        <BigIncrementButton
          x={margin.margin * 2 + triangleW * 2}
          y={0}
          triangleW={triangleW}
          buttonH={triangleW - margin.shortDiagonal}
          onClick={() => triangleOnClick((index == 2 || index == 3) ? 0 : 2, triangleOnClickDirection)}
          direction={triangleOnClickDirection}
          rotateText={(index == 1) ? 90 : 0}
          animations={animations}
          index={index * 3 + 1}
        />
      </g>
    }
  </g>
}

export const CornerBorder = ({x, y, w, h, triangleW, borderH, margin, rotation}) => {
  return <g>
    <BorderRect
      x={x} y={y + h - borderH}
      h={borderH} w={triangleW}
    />
    <BorderRect
      x={x + margin.margin + triangleW} y={y + h - borderH}
      h={borderH} w={triangleW}
    />
    <BorderRect
      x={x + w - borderH} y={y}
      h={triangleW} w={borderH}
    />
    <BorderRect
      x={x + w - borderH} y={y + margin.margin + triangleW}
      h={h - y + margin.margin + triangleW} w={borderH}
    />
  </g>
}

const rotations = [0, 90, 180, 270]

export const GameWrapper = ({gameBoardPercent, marginPercent, currentScore, buttonFunctions, gameNumber, animations, scoreInfo, isArchive}) => {
  const [viewWidth, setViewWidth] = useState(0)
  const [viewHeight, setViewHeight] = useState(0)
  const ref = useRef(null)

  useEffect(() => {
    setViewWidth(ref.current.clientWidth)
    setViewHeight(ref.current.clientHeight)
  })

  const isSmallDisplay = viewWidth < 500
  const [svgW, svgH] = [1000, 1000]
  const gameBoardSize = svgW * gameBoardPercent
  const wrapperSize = (svgW - gameBoardSize) / 2
  const margin = marginDetails(marginPercent * svgW)
  const triangleW = (gameBoardSize - margin.margin * 3) / 3 
  const nonBorderWrapperH = margin.longDiagonal * 2 + margin.margin + triangleW * 1.5
  const cornerTriangleH = triangleW * .4

  return <svg ref={ref} height="100%" width="100%"
    viewBox={`${-1 * svgW/2} ${-1 * svgH/2} ${svgW} ${svgH}`} preserveAspectRatio="none">
      {
        rotations.map((rotation, index) => (
          <g transform={`rotate(${rotation})`} key={index}>
            <Controls
              gameBoardSize={gameBoardSize} 
              margin={margin} 
              wrapperSize={wrapperSize}
              triangleW={triangleW}
              nonBorderWrapperH={nonBorderWrapperH}
              index={index}
              buttonFunctions={buttonFunctions}
              animations={animations}
              isSmallDisplay={isSmallDisplay}
            />
            <CornerBorder
              x={gameBoardSize/2 + margin.half} y={gameBoardSize/2 + margin.half}
              w={wrapperSize - margin.half} h={wrapperSize - margin.half} 
              borderH={wrapperSize - nonBorderWrapperH - margin.half} triangleW={triangleW}
              margin={margin}
            />
            <CornerTrianglesOfSquare
              x={gameBoardSize/2 + margin.half} y={gameBoardSize/2 + margin.half}
              w={nonBorderWrapperH - margin.margin} h={nonBorderWrapperH - margin.margin} 
              triangleHeight={cornerTriangleH}
            />
          </g>
        ))
      }
    <AlignTitle
      x={-1 * svgW/2 + (wrapperSize - nonBorderWrapperH + margin.half)} 
      y={-1 * svgH/2 + (wrapperSize - nonBorderWrapperH + margin.half)}
      w={nonBorderWrapperH - margin.margin} h={nonBorderWrapperH - margin.margin} 
      margin={margin}
      cornerTriangleHeight={cornerTriangleH}
      isArchive={isArchive}
    />
    <CurrentScore
      x={svgW/2 - (wrapperSize - margin.half)} 
      y={-1 * svgH/2 + (wrapperSize - nonBorderWrapperH + margin.half)}
      w={nonBorderWrapperH - margin.margin} h={nonBorderWrapperH - margin.margin} 
      margin={margin}
      cornerTriangleHeight={cornerTriangleH}
      score={currentScore}
    />
    <ResetEtc
      x={svgW/2 - (wrapperSize - margin.half)} 
      y={svgH/2 - (wrapperSize - margin.half)}
      w={nonBorderWrapperH - margin.margin} h={nonBorderWrapperH - margin.margin} 
      margin={margin}
      cornerTriangleHeight={cornerTriangleH}
      buttonFunctions={buttonFunctions}
    />
    <BestScoreToday
      x={-1 * svgW/2 + (wrapperSize - nonBorderWrapperH + margin.half)} 
      y={svgH/2 - (wrapperSize - margin.half)}
      w={nonBorderWrapperH - margin.margin} h={nonBorderWrapperH - margin.margin} 
      margin={margin}
      cornerTriangleHeight={cornerTriangleH}
      scoreInfo={scoreInfo}
      bestScore={scoreInfo.bestScore}
      bestMoves={scoreInfo.bestMovesString}
      gameNumber={scoreInfo.gameNumber}
      isArchive={isArchive}
    />
  </svg>
}
export default Controls;