import React, { useState, useEffect, useRef } from 'react'
import { motion, AnimatePresence } from 'framer-motion';
import MyScoreView from './MyScoreView';
import { copyStringToClipboard, convertMovesToEmojis } from '../logic/ShareStrings'

const SettingsPopup = ({closePopup, settings, setAnimationSettings}) => {
  const ref = useRef(null)
  const [viewHeight, setViewHeight] = useState(0)
  const [buttonsChecked, setButtonsChecked] = useState(settings.animateButtons)
  const [copiedSuccess, setCopiedSuccess] = useState(false)

  useEffect(() => {
    const updateDimensions = () => {
      if (ref.current) {
        setViewHeight(ref.current.clientHeight)
      }
    };

    // Call the updateDimensions function initially and on each update
    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);


  const containerStyle = {
    height: '100%', 
    width: '100%', 
    backdropFilter: 'blur(8px)',
    backgroundColor: 'rgba(255, 255, 255)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between'
  }

  const xButtonStyle = {
    position: 'absolute',
    height: '8%',
    width: '8%',
    top: '3%',
    right: '3%',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
  };

  const containerTopBodyStyle = {
    fontFamily: 'ProtoMono-Regular',
    textAlign: 'center',
    // width: '92%',
    padding: '10%',
    paddingTop: '10%',
    fontSize: `${viewHeight * .075}px`,
    color: '#314f4d'
  }

  const settingsOptionsStyle = {
    fontFamily: 'Nunito',
    textAlign: 'center',
    // width: '92%',
    // width: '92%',
    padding: '10%',
    paddingBottom: '1%',
    fontSize: `${viewHeight * .04}px`,
    color: '#314f4d',
    display:'flex',
    flexDirection: 'row'
  }

  const optionNameStyle={
    flex: 3,
  }
  const checkboxStyle={
    flex: 1
  }

  const letsGoButtonStyle = {
    display: "flex",
    fontFamily: 'Nunito',
    fontSize: `${viewHeight * .04}px`,
    padding: "2% 4%",
    margin: "3%",
    backgroundColor: "#569FA9",
    color: "white",
    borderRadius: "5px",
    cursor: "pointer",
    flexBasis: '50%',
    textAlign: 'center',
    justifyContent: "center",
    alignItems: "center"
  }
  const buttonsRow = {
    display: 'flex',
    width: '75%',
    padding: '10%',
    justifyContent: 'center',
    flexDirection: 'row'
  }
  const shareStyle = {
    marginLeft: '6%',
    marginRight: '-5%'
  }
  const topSection = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  }

  const handleButtonAnimationCheckbox = () => {
    const newSettings = {
      animateButtons: !buttonsChecked
    }
    setButtonsChecked(!buttonsChecked)
    setAnimationSettings(newSettings)
  }

  return <div ref={ref} style={containerStyle}>
    <div style={topSection}>
      <div style={containerTopBodyStyle}>
        {'Settings:'}
      </div>
      <div style={settingsOptionsStyle}>
        <div style={optionNameStyle}>Button animations enabled:</div>
        <div style={checkboxStyle}>
          <input type='checkbox' checked={buttonsChecked} onChange={handleButtonAnimationCheckbox}/>
        </div>
      </div>
    </div>
    
    <img 
      style={xButtonStyle}
      src={process.env.PUBLIC_URL + '/svgs/close.svg'}
      onClick={closePopup}
    />
    <div style={buttonsRow}>
      <motion.div 
        style={letsGoButtonStyle}
        whileHover={{ scale: 1.12, backgroundColor: "#314f4d" }}
        onClick={closePopup}
        >
        Exit
      </motion.div>
    </div>
    
  </div>
}

export default SettingsPopup;


