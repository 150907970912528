const emojiMappingNumbers = {
  '0': '0️⃣',
  '1': '1️⃣',
  '2': '2️⃣',
  '3': '3️⃣',
  '4': '4️⃣',
  '5': '5️⃣',
  '6': '6️⃣',
  '7': '7️⃣',
  '8': '8️⃣',
  '9': '9️⃣',
};
export function convertMovesToEmojis(moveCount, movesString, gameNumber, alignedNumber) {
  const emojiMapping = {
    '+': '➕',
    '-': '➖',
    'L': '◀️',
    'R': '▶️',
    'U': '🔼',
    'D': '🔽',
  };

  const moves = movesString.split(',');
  const counts = {
    'U': 0,
    'D': 0,
    'L': 0,
    'R': 0,
    '+': 0,
    '-': 0
  }

  const moveCounts = moves.map((move) => {
    const lastCharacter = move.slice(-1);
    counts[lastCharacter] = (counts[lastCharacter] || 0) + 1;
    return counts;
  }, {});



  const upsAndDowns = counts['U'] + counts['D']
  const leftsAndRights = counts['R'] + counts['L']
  const plusAndMinus = counts['+'] + counts['-']
  const digits = Math.max(upsAndDowns, leftsAndRights, plusAndMinus).toString().length
  const threeAlignedNumbers = `${emojiMappingNumbers[alignedNumber.toString()]}`.repeat(3)

  const output = `Ali9n #${gameNumber} - ${moveCount} moves
${numberToEmojis(plusAndMinus, digits)}➕➖    ${threeAlignedNumbers}
${numberToEmojis(upsAndDowns, digits)}🔼🔽    ${threeAlignedNumbers}
${numberToEmojis(leftsAndRights, digits)}◀️▶️    ${threeAlignedNumbers}
ali9ngame.com`

  return output.trim();
}

function numberToEmojis(number, digitCount) {
  const numberString = number.toString().padStart(digitCount, '0');
  let result = '';

  for (let i = 0; i < numberString.length; i++) {
    const digit = numberString[i];
    const emoji = emojiMappingNumbers[digit];
    result += emoji;
  }

  return result;
}

export function copyStringToClipboard(string) {
  if (navigator.clipboard && window.isSecureContext) {
    navigator.clipboard.writeText(string)
      .then(() => {
      })
      .catch((error) => {
        console.error('Failed to copy string: ', error);
      });
  } else {
    const textarea = document.createElement('textarea');
    textarea.value = string;
    textarea.style.position = 'fixed';
    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();

    try {
      const success = document.execCommand('copy');
      if (success) {
      } else {
        console.error('Failed to copy string');
      }
    } catch (error) {
      console.error('Failed to copy string: ', error);
    }

    document.body.removeChild(textarea);
  }
}