import React, { useLayoutEffect, useState, useRef, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { API } from 'aws-amplify'
import { GameWrapper } from './Controls';
import InfoPopup from './InfoPopup';
import GameWinPopup from './GameWinPopup';
import StatsPopup from './StatsPopup';
import SettingsPopup from './SettingsPopup';



  // const colors = ["5ac3ed","979eee","c089e6","ed6dd6","f482b6","f7b571","fae300","c5ea50","73e0b1"]
  // const colors =  ["#dd5f74","#de826a","#e8af6e","#aecf76","#6ccf99","#6abec4","#7a95d6","#9370d8","#d780ce"]
  // const colors = ["#f5d9c8","#eac4d5","#d0bad4","#b5b0d2","#9ba6d0","#95b8d1","#b8e0d4","#d6eadf","#ebe9bd"]
  // const colors = ["#b69174","#b07d82","#9e778d","#82718d","#6e6e8b","#6b808c","#80a088","#93a589","#b1a56d"]
  // const colors = ["#88dba8","#a9dc99","#e3d486", "#e4b18c","#d97f8f","#eb87c8","#af7cdb","#787ada","#76b0db"]
  // const colors = ["#F48F8F","#D889C2","#BD79C8", "#AA627C","#834044", "#A45E41","#C1934E","#D4BE6D","#EBA882"]
  const colors = ["#4A837F","#569278","#8AB29A", "#A1CEA0","#C5DBB8", "#B7E8C5","#A8DFD2","#8DC7B9","#569FA9"]
  // const shadowColors = ["#224a47","#2f614b","#abd1bb", "#A1CEA0","#C5DBB8", "#B7E8C5","#A8DFD2","#8DC7B9","#569FA9"]
  // const colors = ['#5ac3ed', '#979eee', '#c089e6', '#ed6dd6', '#f482b6', '#f7b571', '#fae300', '#c5ea50', '#73e0b1']
  // const colors = getHexColorsBetween('#73D5B1', '#B873D5', 9)


function useWindowSize() {
  const [size, setSize] = useState(0);
  useLayoutEffect(() => {
    function updateSize() {
      setSize(Math.min(900, window.innerWidth, window.innerHeight));
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

function mod(n, m) {
  return ((n % m) + m) % m;
}

const Number = ({n, gridSize}) => {
  const textStyle = {
    userSelect: 'none',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none',
    background: 'none', 
    pointerEvents: 'none'
  };

  const margin = .024 * gridSize
  const numberSqSize = ((gridSize - margin * 3)/ 3)

  return (
    <motion.g>
      <polygon
        points={`
          0,0
          0,${numberSqSize}
          ${numberSqSize},${numberSqSize}
          ${numberSqSize},0
        `}
        style={{
          fill: colors[n - 1],
        }}
      />
      <text style={textStyle} x={numberSqSize/2} y={numberSqSize/2} fontSize={`${Math.floor(numberSqSize * .65)}px`} fontFamily='ProtoMono-Regular' textAnchor='middle' dominantBaseline={'middle'} fill='#ffffff'>
        {n}
      </text>
    </motion.g>
  )
}

const GameContainer = ({startingNumbers, gameNumber, remoteScoreInfo, fetchRemoteScores, isArchive, dateOfArchiveGame}) => {
  const sqSize = useWindowSize();
  const [moveCount, setMoveCount] = useState(0)
  const [remoteScoreState, setRemoteScoreState] = useState(remoteScoreInfo)
  const [bestScore, setBestScore] = useState('-')
  const [lastMove, setLastMove] = useState(null)
  const [movesList, setMovesList] = useState([])
  const [bestMovesString, setBestMovesString] = useState(null)
  const [recentWMovesString, setRecentWMovesString] = useState(null)
  const [recentWScore, setRecentWScore] = useState(null)
  const [alignedNumber, setAlignedNumber] = useState(null)
  const [bestGameAlignedNumber, setBestGameAlignedNumber] = useState(null)
  const [animationSettings, setAnimationSettingsState] = useState({
    animateButtons: true
  })

  const [currentStreak, setCurrentStreakState] = useState(null)
  const [maxStreak, setMaxStreakState] = useState(null)
  const [numPlayed, setNumPlayedState] = useState(null)
  const [lastCompletedPuzzleDate, setLastCompletedPuzzleDateState] = useState(null)
  const [bestMoves, setBestMoves] = useState([])
  const [gameWinDetected, setGameWinDetected] = useState(false)
  const popupRef = useRef(null)
  const [movesDisabled, setMovesDisabled] = useState(true)
  
  const closePopupF = (setPopupContents) => {
    setPopupContents(null)
    setTimeout(() => {
      localStorage.setItem('ali9n-exitedInfoPopup', true)
      setMovesDisabled(false)
      restart()
    }, 200);
  }

  const svgGridSize = 300
  const margin = .024 * svgGridSize
  const numberSqSize = ((svgGridSize - margin * 3)/ 3)
  const popupHW = sqSize / 7 * 4
  const winPopupHW = sqSize / 7 * 5

  const [shouldShowInfoPopup, setShouldShowInfoPopup] = useState(false)
  const [popupContents, setPopupContents] = useState(null)
  const [winPopupContents, setWinPopupContents] = useState(null)

  const [animatingGameStart, setAnimatingGameStart] = useState(false)
  const [gameStartAnimDone, setGameStartAnimDone] = useState(false)
  const [animatingGameWin, setAnimatingGameWin] = useState(false)
  const [gameWinAnimDone, setGameWinAnimDone] = useState(false)

  // useEffect(() => {
  //   const functions = [handleIncrementRow, handleIncrementColumn, rotateRow, rotateColumn];

  //   let scrambleTimes = 0
  //   const intervalId = setInterval(() => {
  //     if (scrambleTimes < 60) {
  //       const randomIndex = Math.floor(Math.random() * functions.length);
  //       const randomCol = Math.floor(Math.random() * 3);
  //       const randomDir = Math.random() < 0.5 ? 1 : -1;

  //       const randomFunction = functions[randomIndex];
  //       randomFunction(randomCol, randomDir);
  //       scrambleTimes++;
  //     }
  //   }, 500);

  //   // Cleanup function to clear the interval when the component unmounts
  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, []);

  const [numberPoses, _setNumberPoses] = useState([
    {id: 1, n: startingNumbers[0], pos: {top: 0, left: 0}, appearing: false},
    {id: 2, n: startingNumbers[1], pos: {top: 0, left: 1}, appearing: false},
    {id: 3, n: startingNumbers[2], pos: {top: 0, left: 2}, appearing: false},
    {id: 4, n: startingNumbers[3], pos: {top: 1, left: 0}, appearing: false},
    {id: 5, n: startingNumbers[4], pos: {top: 1, left: 1}, appearing: false},
    {id: 6, n: startingNumbers[5], pos: {top: 1, left: 2}, appearing: false},
    {id: 7, n: startingNumbers[6], pos: {top: 2, left: 0}, appearing: false},
    {id: 8, n: startingNumbers[7], pos: {top: 2, left: 1}, appearing: false},
    {id: 9, n: startingNumbers[8], pos: {top: 2, left: 2}, appearing: false}
  ])
  const numberPosStateRef = useRef(numberPoses)
  const setNumberPoses = poses => {
    numberPosStateRef.current = poses;
    _setNumberPoses(poses);
  };

  const curDate = new Date()
  const currentDateString = isArchive ? dateOfArchiveGame.toLocaleDateString() : curDate.toLocaleDateString();
  const yesterdayDate = new Date()
  yesterdayDate.setDate(curDate.getDate() - 1)
  const yesterdayDateString = yesterdayDate.toLocaleDateString()

  const styles = {
    gameContainer: {
      width: `${sqSize}px`,
      height: `${sqSize}px`,
      top: '0px',
      bottom: '0px',
      left: '0px',
      right: '0px',
      backgroundColor: '#ffffff',
      position: 'absolute',
      margin: 'auto',
      overflow: 'hidden'
    },
    numberContainer: {
      width: 'calc(100% / 7 * 3)',
      height: 'calc(100% / 7 * 3)',
      position: 'absolute',
      boxSizing: 'border-box',
      top: `calc(100% / 7 * 2)`,
      left: `calc(100% / 7 * 2)`,
      overflow: 'hidden'
    },
    popup: {
      width: `${popupHW}px`,
      height: `${popupHW}px`,
      position: 'absolute',
      boxSizing: 'border-box',
      top: `${sqSize / 2 - (popupHW / 2)}px`,
      left: `${sqSize / 2 - (popupHW / 2)}px`,
      overflow: 'hidden',
      borderRadius: '5px',
      boxShadow: '0 4px 23px 0 rgba(0,0,0,.2)'
      // border: '4px solid white'
    },
    winPopup: {
      width: `${winPopupHW}px`,
      height: `${winPopupHW}px`,
      position: 'absolute',
      boxSizing: 'border-box',
      top: `${sqSize / 2 - (winPopupHW / 2)}px`,
      left: `${sqSize / 2 - (winPopupHW / 2)}px`,
      overflow: 'hidden',
      borderRadius: '5px',
      boxShadow: '0 4px 23px 0 rgba(0,0,0,.2)'
      // border: '4px solid white'
    }
  }

  const [growingIds, setGrowingIds] = useState([])
  const [shrinkingIds, setShrinkingIds] = useState([])

  const [sizing1, setSizing1] = useState('none')
  const [sizing2, setSizing2] = useState('none')
  const [sizing3, setSizing3] = useState('none')
  const [sizing4, setSizing4] = useState('none')
  const [sizing5, setSizing5] = useState('none')
  const [sizing6, setSizing6] = useState('none')
  const [sizing7, setSizing7] = useState('none')
  const [sizing8, setSizing8] = useState('none')
  const [sizing9, setSizing9] = useState('none')

  const idToSizingMap = {
    1: {
      get: sizing1,
      set: setSizing1
    }, 
    2: {
      get: sizing2,
      set: setSizing2
    }, 
    3: {
      get: sizing3,
      set: setSizing3
    }, 
    4: {
      get: sizing4,
      set: setSizing4
    }, 
    5: {
      get: sizing5,
      set: setSizing5
    }, 
    6: {
      get: sizing6,
      set: setSizing6
    }, 
    7: {
      get: sizing7,
      set: setSizing7
    }, 8: {
      get: sizing8,
      set: setSizing8
    }, 0: {
      get: sizing9,
      set: setSizing9
    }
  }

  const setSizing = (sizing, id) => {
    const adjustedId = id % 9 
    idToSizingMap[adjustedId].set(sizing)
  }

  const getSizing = (id) => {
    const adjustedId = id % 9 
    return idToSizingMap[adjustedId].get
  }

  useEffect(() => {
    // Retrieve the stored score from local storage
    const storedHighScore = localStorage.getItem(`ali9n-${currentDateString}-bestScore`)
    const storedMovesLIst = localStorage.getItem(`ali9n-${currentDateString}-bestMoves`);
    const storedAlignedNumber = localStorage.getItem(`ali9n-${currentDateString}-bestGameAlignedNumber`);
    const storedInfoPopup = localStorage.getItem('ali9n-exitedInfoPopup');
    const animationSettings = localStorage.getItem('ali9n-animationSettings')

    const archiveBestScore = localStorage.getItem('ali9nArchive-' + gameNumber + '-bestScore')
    const archiveBestMoves = localStorage.getItem('ali9nArchive-' + gameNumber + '-bestMoves')
    const archiveBestGameAlignedNumber = localStorage.getItem('ali9nArchive-' + gameNumber + '-bestGameAlignedNumber')

    // Don't do streak stuff if it's an archive game
    if (!isArchive) {
      const maxStreakStorage = localStorage.getItem('ali9n-maxStreak')
      const lastCompletedPuzzleDateStorage = localStorage.getItem('ali9n-lastCompletedPuzzleDate')
      const currentStreakStorage = localStorage.getItem('ali9n-currentStreak')
      const numPlayedStorage = localStorage.getItem('ali9n-numberPlayed')

      if (currentStreakStorage && lastCompletedPuzzleDateStorage) {
        if (lastCompletedPuzzleDateStorage == yesterdayDateString || 
          lastCompletedPuzzleDateStorage == currentDateString) {
          setCurrentStreak(currentStreakStorage)
          setLastCompletedPuzzleDate(lastCompletedPuzzleDateStorage)
        } else {
          setCurrentStreak(0)
          setLastCompletedPuzzleDate(lastCompletedPuzzleDateStorage)
        }
      }
      setMaxStreakState(maxStreakStorage)
      if (numPlayedStorage) {
        setNumPlayed(numPlayedStorage)
      }
    }

    if (storedHighScore && archiveBestScore) {
      if (parseInt(storedHighScore) < parseInt(archiveBestScore)) {
        setBestScore(storedHighScore);
        setBestMovesString(storedMovesLIst)
        setBestGameAlignedNumber(storedAlignedNumber)
      } else {
        console.log("Setting archive)")
        setBestScore(archiveBestScore);
        setBestMovesString(archiveBestMoves)
        setBestGameAlignedNumber(archiveBestGameAlignedNumber)
      }
    } else if (storedHighScore) {
      setBestScore(storedHighScore);
      setBestMovesString(storedMovesLIst)
      setBestGameAlignedNumber(storedAlignedNumber)
    }

    if (storedInfoPopup) {
      setShouldShowInfoPopup(false)
    } else {
      setShouldShowInfoPopup(true)
    }

    if (animationSettings) {
      setAnimationSettings(JSON.parse(animationSettings))
    }
  }, [startingNumbers]);

  const setCurrentStreak = (newStreak) => {
    setCurrentStreakState(parseInt(newStreak))
    localStorage.setItem('ali9n-currentStreak', newStreak)
  }

  const setMaxStreak = (newMaxStreak) => {
    setMaxStreakState(parseInt(newMaxStreak))
    localStorage.setItem('ali9n-maxStreak', newMaxStreak)
  }

  const setLastCompletedPuzzleDate = (lastCompletedDate) => {
    setLastCompletedPuzzleDateState(lastCompletedDate)
    localStorage.setItem('ali9n-lastCompletedPuzzleDate', lastCompletedDate)
  }

  const setNumPlayed = (numPlayed) => {
    setNumPlayedState(parseInt(numPlayed))
    localStorage.setItem('ali9n-numberPlayed', numPlayed)
  }

  const setAnimationSettings = (settings) => {
    setAnimationSettingsState(settings)
    localStorage.setItem('ali9n-animationSettings', JSON.stringify(settings))
  }

  useEffect(() => {
    var n = numberPoses[1].n
    var wonGame = true
    for(let i = 0; i < 9; i++) {
      if (numberPoses[i].n != n) {
        wonGame = false
      }
    }
    if (wonGame && !gameWinDetected) {
      gameFinished(n)
    }
  }, [numberPoses]);

  useEffect(() => {
    setRemoteScoreState(remoteScoreInfo)
  }, [remoteScoreInfo])


  const gameFinished = (n) => {
    setGameWinDetected(true)
    winAnimation()

    setRecentWMovesString(movesList.toString())
    setRecentWScore(moveCount)
    setAlignedNumber(n)

    const oldScore = bestScore == '-' ? null : bestScore
    if (!isArchive) {
      updateRemoteScore(moveCount, movesList.toString(), oldScore)
    }

    if (bestScore == '-' || bestScore > moveCount) {
      if (!isArchive) {
        localStorage.setItem('ali9n-' + currentDateString + '-bestScore', moveCount)
        localStorage.setItem('ali9n-' + currentDateString + '-bestMoves', movesList)
        localStorage.setItem('ali9n-' + currentDateString + '-bestGameAlignedNumber', n)
      } else {
        localStorage.setItem('ali9nArchive-' + gameNumber + '-bestScore', moveCount)
        localStorage.setItem('ali9nArchive-' + gameNumber + '-bestMoves', movesList)
        localStorage.setItem('ali9nArchive-' + gameNumber + '-bestGameAlignedNumber', n)
      }

      setBestScore(moveCount)
      setBestMoves(movesList)
      setBestGameAlignedNumber(n)
      setBestMovesString(movesList.toString())
      if (!isArchive) {
        // Only do streak stuff if we're not in the archive
        if (lastCompletedPuzzleDate == null || currentStreak == null) {
          setLastCompletedPuzzleDate(currentDateString)
          setCurrentStreak(1)
          if (maxStreak == null) {
            setMaxStreak(1)
          }
          setNumPlayed((numPlayed || 0) + 1)
        } else if (lastCompletedPuzzleDate && lastCompletedPuzzleDate == yesterdayDateString) {
          setLastCompletedPuzzleDate(currentDateString)
          const newStreak = (currentStreak || 0) + 1
          const newmax = maxStreak || 0
          setCurrentStreak(newStreak)
          if (newStreak > newmax) {
            setMaxStreak(newStreak)
          }
          setNumPlayed((numPlayed || 0) + 1)
        } else if (lastCompletedPuzzleDate != currentDateString) {
          setLastCompletedPuzzleDate(currentDateString)
          setCurrentStreak(1)
          if (maxStreak == null || maxStreak < 1) {
            setMaxStreak(1)
          }
          setNumPlayed((numPlayed || 0) + 1)
        }
      }
    }
  }

  const restart = () => {
    setLastMove('restart')
    const maxId = numberPosStateRef.current.reduce((max, pos) => {
      return pos.id > max ? pos.id : max;
    }, -Infinity)
    const newMinId = maxId == 9 ? 10 : 1
    const newPoses = [
      {id: newMinId, n: startingNumbers[0], pos: {top: 0, left: 0}, appearing: false},
      {id: newMinId + 1, n: startingNumbers[1], pos: {top: 0, left: 1}, appearing: false},
      {id: newMinId + 2, n: startingNumbers[2], pos: {top: 0, left: 2}, appearing: false},
      {id: newMinId + 3, n: startingNumbers[3], pos: {top: 1, left: 0}, appearing: false},
      {id: newMinId + 4, n: startingNumbers[4], pos: {top: 1, left: 1}, appearing: false},
      {id: newMinId + 5, n: startingNumbers[5], pos: {top: 1, left: 2}, appearing: false},
      {id: newMinId + 6, n: startingNumbers[6], pos: {top: 2, left: 0}, appearing: false},
      {id: newMinId + 7, n: startingNumbers[7], pos: {top: 2, left: 1}, appearing: false},
      {id: newMinId + 8, n: startingNumbers[8], pos: {top: 2, left: 2}, appearing: false}
    ]
    setMoveCount(0)
    setMovesList([])
    setNumberPoses(newPoses)
    setGameWinDetected(false)
    setAnimatingGameStart(true)
  }

  const winAnimation = () => {
    setLastMove('win')
    const currentNums = numberPosStateRef.current
    const maxId = currentNums.reduce((max, pos) => {
      return pos.id > max ? pos.id : max;
    }, -Infinity)
    const newMinId = maxId == 9 ? 10 : 1
    const newPoses = [
      {id: newMinId, n: currentNums[0].n, pos: {top: 0, left: 0}, appearing: false},
      {id: newMinId + 1, n: currentNums[1].n, pos: {top: 0, left: 1}, appearing: false},
      {id: newMinId + 2, n: currentNums[2].n, pos: {top: 0, left: 2}, appearing: false},
      {id: newMinId + 3, n: currentNums[3].n, pos: {top: 1, left: 0}, appearing: false},
      {id: newMinId + 4, n: currentNums[4].n, pos: {top: 1, left: 1}, appearing: false},
      {id: newMinId + 5, n: currentNums[5].n, pos: {top: 1, left: 2}, appearing: false},
      {id: newMinId + 6, n: currentNums[6].n, pos: {top: 2, left: 0}, appearing: false},
      {id: newMinId + 7, n: currentNums[7].n, pos: {top: 2, left: 1}, appearing: false},
      {id: newMinId + 8, n: currentNums[8].n, pos: {top: 2, left: 2}, appearing: false}
    ]
    setNumberPoses(newPoses)
    setAnimatingGameWin(true)
  }

  const appendMoveToList = (move) => {
    const moves = movesList.slice()
    moves.push(move)
    setMovesList(moves)
  }

  const handleIncrementColumn = (colIndex, i) => {
    const appliedMove = (i == -1) ? 'minus' : 'plus'
    const moveSymbol = (i == -1) ? '-' : '+'

    const nowGrowingIds = [...growingIds]
    const nowShrinkingIds = [...shrinkingIds]

    const updatedPoses = numberPoses.map((numberPos, listIndex) => {
      var newPos = numberPos
      if (numberPos.pos.left == colIndex) {
        newPos = {
          n: mod(numberPos.n + i - 1, 9) + 1,
          pos: numberPos.pos,
          appearing: numberPos.appearing,
          id: numberPos.id
        }
        i == 1 ? nowGrowingIds.push(numberPos.id) : nowShrinkingIds.push(numberPos.id)
        i == 1 ? setSizing('growing', numberPos.id) : setSizing('shrinking', numberPos.id)
      }
      return newPos
    })
    setGrowingIds(nowGrowingIds)
    setShrinkingIds(nowShrinkingIds)
    setNumberPoses(updatedPoses)
    setMoveCount(moveCount + 1)
    setLastMove(appliedMove)
    appendMoveToList(`C${colIndex}${moveSymbol}`)
  }

  const handleIncrementRow = (rowIndex, i) => {
    const appliedMove = (i == -1) ? 'minus' : 'plus'
    const moveSymbol = (i == -1) ? '-' : '+'

    const nowGrowingIds = [...growingIds]
    const nowShrinkingIds = [...shrinkingIds]

    const updatedPoses = numberPoses.map((numberPos, listIndex) => {
      var newPos = numberPos
      if (numberPos.pos.top == rowIndex) {
        newPos = {
          n: mod(numberPos.n + i - 1, 9) + 1,
          pos: numberPos.pos,
          appearing: numberPos.appearing,
          id: numberPos.id
        }
        i == 1 ? nowGrowingIds.push(numberPos.id) : nowShrinkingIds.push(numberPos.id)
        i == 1 ? setSizing('growing', numberPos.id) : setSizing('shrinking', numberPos.id)
      }
      return newPos
    })

    setGrowingIds(nowGrowingIds)
    setShrinkingIds(nowShrinkingIds)
    setNumberPoses(updatedPoses)
    setMoveCount(moveCount + 1)
    setLastMove(appliedMove)
    appendMoveToList(`R${rowIndex}${moveSymbol}`)
  }

  const rotateColumn = (colIndex, i) => {
    const appliedMove = (i == -1) ? 'up' : 'down'
    const moveSymbol = (i == -1) ? 'U' : 'D'

    const updatedPoses = numberPoses.map((numberPos, listIndex) => {
      var newPos = numberPos
      if (numberPos.pos.left == colIndex) {
        const newTop = mod(numberPos.pos.top + i, 3)
        newPos = {
          n: numberPos.n, 
          pos: {top: newTop, left: numberPos.pos.left},
          appearing: ((newTop == 0 && i == 1) || (newTop == 2 && i == -1)) ? !numberPos.appearing : !!numberPos.appearing,
          id: numberPos.id
        }
      } else {
        newPos = {
          n: numberPos.n,
          pos: numberPos.pos,
          appearing: numberPos.appearing,
          id: numberPos.id
        }
      }
      return newPos;
    });
    setNumberPoses(updatedPoses)
    setMoveCount(moveCount + 1)
    setLastMove(appliedMove)
    appendMoveToList(`C${colIndex}${moveSymbol}`)
  };

  const rotateRow = (rowIndex, i) => {
    const moveSymbol = (i == -1) ? 'L' : 'R'
    const updatedPoses = numberPoses.map((numberPos, listIndex) => {
      var newPos = numberPos
      if (numberPos.pos.top == rowIndex) {
        const newLeft = mod(numberPos.pos.left + i, 3)
        newPos = {
          n: numberPos.n,
          pos: {top: numberPos.pos.top, left: newLeft},
          appearing: ((newLeft == 0 && i == 1) || (newLeft == 2 && i == -1)) ? !numberPos.appearing : !!numberPos.appearing,
          id: numberPos.id
        }
      }
      return newPos;
    });
    
    const lastm = (i == -1) ? 'left' : 'right'
    setNumberPoses(updatedPoses)
    setMoveCount(moveCount + 1)
    setLastMove(lastm)
    appendMoveToList(`R${rowIndex}${moveSymbol}`)
  };


  const numberVariants = (numberPos) => {
    return {
      initial: args => {
        const lastMove = args && args.lastMove
        return initialStyleForPos({lastMove: lastMove, numberPos: numberPos})
      },
      animate: args => {
        const lastMove = args && args.lastMove
        const growing = args && args.growing
        const shrinking = args && args.shrinking

        return animateForMove({lastMove: lastMove, growing: growing, shrinking: shrinking, numberPos: numberPos})
      },
      exit: args => {
        const lastMove = args && args.lastMove
        return exitStyleForPos({lastMove: lastMove, numberPos: numberPos})
      }
    }
  }

  const initialStyleForPos = ({lastMove, numberPos}) => {
    if (lastMove == 'down'){
      return {
        x: (margin/2 + numberPos.pos.left * (numberSqSize + margin)), 
        y: margin/2 + -1 * (numberSqSize + margin)
      }
    } else if (lastMove  == 'up'){
      return {
        x: (margin/2 + numberPos.pos.left * (numberSqSize + margin)), 
        y: margin/2 + 3 * (numberSqSize + margin)
      }
    } else if (lastMove  == 'right'){
      return {
        x: (margin/2 + -1 * (numberSqSize + margin)), 
        y: margin/2 + numberPos.pos.top * (numberSqSize + margin)
      }
    } else if (lastMove  == 'left'){
      return {
        x: (margin/2 + 3 * (numberSqSize + margin)), 
        y: margin/2 + numberPos.pos.top * (numberSqSize + margin)
      }
    } else if (lastMove  == 'restart'){
      const offset = numberPos.pos.top == 1 ? -3 : 3
      // return {
      //   x: (margin/2 + (numberPos.pos.left + offset) * (numberSqSize + margin)), 
      //   y: margin/2 + numberPos.pos.top * (numberSqSize + margin),
      //   rotate: 0,
      // }
      return {
        x: (margin/2 + numberPos.pos.left * (numberSqSize + margin)), 
        y: margin/2 + numberPos.pos.top * (numberSqSize + margin),
        scaleY: 0,
        rotate: 0,
      }
    } else if (lastMove  == 'win'){
      const offset = numberPos.pos.top == 1 ? -3 : 3
      return {
        x: (margin/2 + numberPos.pos.left * (numberSqSize + margin)), 
        y: margin/2 + numberPos.pos.top * (numberSqSize + margin),
        scaleY: 0,
        rotate: 0,
      }
    } else {
      return false
    }
  }
  
  const movementTransition = {
    ease: 'backOut',
    duration: .45
  }

  const exitStyleForPos = ({lastMove, numberPos}) => {
    if (lastMove  == 'down'){
      return {
        x: (margin/2 + numberPos.pos.left * (numberSqSize + margin)), 
        y: margin/2 + 3 * (numberSqSize + margin),
        transition: movementTransition
      }
    } else if (lastMove  == 'up'){
      return {
        x: (margin/2 + numberPos.pos.left * (numberSqSize + margin)), 
        y: margin/2 + -1 * (numberSqSize + margin),
        transition: movementTransition
      }
    } else if (lastMove  == 'right'){
      return {
        x: (margin/2 + 3 * (numberSqSize + margin)), 
        y: margin/2 + numberPos.pos.top * (numberSqSize + margin),
        transition: movementTransition
      }
    } else if (lastMove  == 'left'){
      return {
        x: (margin/2 + -1 * (numberSqSize + margin)), 
        y: margin/2 + numberPos.pos.top * (numberSqSize + margin),
        transition: movementTransition
      }
    } else if (lastMove  == 'restart'){
      return {
        transition: { duration: .3, delay: numberPos.pos.left * .05 + numberPos.pos.top * .15, ease: 'backIn'},
        scaleY: 0
      }
    } else if (lastMove  == 'win'){
      return {
        transition: { duration: .3, delay: numberPos.pos.left * .05 + numberPos.pos.top * .15, ease: 'backIn'},
        scaleY: 0
      }
    } else {
      return { 
        x: margin/2 + numberPos.pos.left * (numberSqSize + margin), 
        y: margin/2 + numberPos.pos.top * (numberSqSize + margin),
        transition: movementTransition
      }
    }
  }

  const animateForMove = ({lastMove, growing, shrinking, numberPos}) => {
    if (lastMove  == 'restart'){
      return {
        x: margin/2 + numberPos.pos.left * (numberSqSize + margin), 
        y: margin/2 + numberPos.pos.top * (numberSqSize + margin),
        rotate: 0,
        scaleY: 1,
        opacity: 1,
        transition: {
          duration: .4,
          delay: .3 + numberPos.pos.left * .05 + numberPos.pos.top * .15,
          ease: 'backOut'
        }
      }
    } else if (lastMove == 'win'){
      return {
        x: margin/2 + numberPos.pos.left * (numberSqSize + margin), 
        y: margin/2 + numberPos.pos.top * (numberSqSize + margin),
        rotate: 0,
        scaleY: 1,
        opacity: 1,
        transition: {
          duration: 1,
          delay: .3 + numberPos.pos.left * .05 + numberPos.pos.top * .15,
          ease: 'backOut'
        }
      }
    } else if (growing || shrinking) {
      return { 
        x: margin/2 + numberPos.pos.left * (numberSqSize + margin), 
        y: margin/2 + numberPos.pos.top * (numberSqSize + margin),
        scale: growing ? [1, 1.05, 1] : (shrinking ? [1, .95, 1] : 1),
        transition: {
          duration: .5,
          times: [0, .5, 1],
          onComplete: () => setSizing('none', numberPos.id),
          ease: 'backOut'
        }
      }
    } else {
      return { 
        x: margin/2 + numberPos.pos.left * (numberSqSize + margin), 
        y: margin/2 + numberPos.pos.top * (numberSqSize + margin),
        transition: movementTransition
      }
    }
  }

  const updateRemoteScore = (score, moveString, oldScore) => {
    const apiName = 'Scores'
    const path = '/scores'
    const requestInfo = {
      headers: {},
      body: {
        gameNumber: gameNumber,
        envId: 123987,
        gameBoard: startingNumbers.join(""),
        score: score,
        movesString: moveString
      }
    }
    if (oldScore) {
      requestInfo['body']['oldScore'] = oldScore
    }

    if (score < remoteScoreState.highScore) {
      remoteScoreState['highScore'] = score
    }

    try {
      if (score < 10) {
        remoteScoreState['buckets'][0]['count'] = remoteScoreState['buckets'][0]['count'] + 1
      } else if (score < 15) {
        remoteScoreState['buckets'][1]['count'] = remoteScoreState['buckets'][1]['count'] + 1
      } else if (score < 20) {
        remoteScoreState['buckets'][2]['count'] = remoteScoreState['buckets'][2]['count'] + 1
      } else if (score < 30) {
        remoteScoreState['buckets'][3]['count'] = remoteScoreState['buckets'][3]['count'] + 1
      } else if (score < 50) {
        remoteScoreState['buckets'][4]['count'] = remoteScoreState['buckets'][4]['count'] + 1
      } else if (score < 60) {
        remoteScoreState['buckets'][5]['count'] = remoteScoreState['buckets'][5]['count'] + 1
      }
    } catch (err) {
      console.log("Error adding new score to remote info");
    }
   

    setRemoteScoreState(remoteScoreState)

    API.post(apiName, path, requestInfo)
      .then((response) => {
        fetchRemoteScores()
      })
      .catch((error) => {
        console.log(error.response)
      })
  }

  // POPUP STUFF
  const closePopup = () => {
    localStorage.setItem('ali9n-exitedInfoPopup', true)
    setShouldShowInfoPopup(false)
    setPopupContents(null)
    setWinPopupContents(null)
    setTimeout(() => {
      // Call your function here
      setMovesDisabled(false)
    }, 200);
  }

  const closeWinPopupAndPlayAgain = () => {
    setPopupContents(null)
    setWinPopupContents(null)
    restart()
    setTimeout(() => {
      // Call your function here
      setMovesDisabled(false)
    }, 200);
  }

  const openInformationPopup = () => {
    setPopupContents(<InfoPopup closePopup={closePopup}/>)
    setMovesDisabled(true)
  }

  const openWinPopup = () => {
    console.log(isArchive)
    setWinPopupContents(<GameWinPopup 
      closePopup={closePopup} 
      playAgain={closeWinPopupAndPlayAgain}
      scoreInfo={{
        bestScore: bestScore, 
        bestMovesString: bestMovesString,
        currentStreak: currentStreak,
        maxStreak: maxStreak,
        gameNumber: gameNumber,
        numPlayed: numPlayed,
        thisWScore: recentWScore,
        thisWMovesString: recentWMovesString,
        alignedNumber: alignedNumber,
        bestGameAlignedNumber: bestGameAlignedNumber,
        remoteScoreInfo: remoteScoreState
      }}
      isArchive={isArchive}
    />)
  }

  const openStatsPopup = () => {
    setPopupContents(<StatsPopup 
      closePopup={closePopup} 
      scoreInfo={{
        bestScore: bestScore, 
        bestMovesString: bestMovesString,
        currentStreak: currentStreak,
        maxStreak: maxStreak,
        gameNumber: gameNumber,
        numPlayed: numPlayed,
        thisWScore: recentWScore,
        thisWMovesString: recentWMovesString,
        alignedNumber: alignedNumber,
        bestGameAlignedNumber: bestGameAlignedNumber,
        remoteScoreInfo: remoteScoreState
      }}
      isArchive={isArchive}
    />)
  }

  const openSettingsPopup = () => {
    setWinPopupContents(<SettingsPopup 
      closePopup={closePopup} 
      settings={animationSettings}
      setAnimationSettings={setAnimationSettings}
    />)
  }


  const doneAnimatingGameStart = () => {
    setMovesDisabled(false)
    setAnimatingGameStart(false)
  }

  const doneAnimatingGameWin = () => {
    setAnimatingGameWin(false)
    openWinPopup()
  }

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        closePopup();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [startingNumbers]);

  useEffect(() => {
    if(shouldShowInfoPopup) {
      setPopupContents(<InfoPopup closePopup={() => closePopupF(setPopupContents)}/>)
    } else {
      restart()
    }
  }, [shouldShowInfoPopup]);


  return <div style={styles.gameContainer}> 
    <GameWrapper 
      gameBoardPercent={3/7} 
      marginPercent={1/7 * 3 * .024} 
      currentScore={moveCount}
      scoreInfo={{
        bestScore: bestScore, 
        bestMovesString: bestMovesString,
        currentStreak: currentStreak,
        maxStreak: maxStreak,
        gameNumber: gameNumber,
        numPlayed: numPlayed,
        thisWScore: recentWScore,
        thisWMovesString: recentWMovesString,
        alignedNumber: alignedNumber,
        bestGameAlignedNumber: bestGameAlignedNumber
      }}
      animations={{
        animatingGameStart: animatingGameStart,
        animatingGameWin: animatingGameWin,
        movesDisabled: movesDisabled,
        doneAnimatingGameStart: doneAnimatingGameStart,
        doneAnimatingGameWin: doneAnimatingGameWin,
        animationSettings: animationSettings
      }}
      buttonFunctions={{
        rotateRow: movesDisabled ? () => {} : (gameWinDetected ? openWinPopup : rotateRow),
        rotateColumn: movesDisabled ? () => {} : (gameWinDetected ? openWinPopup : rotateColumn),
        incrementRow: movesDisabled ? () => {} : (gameWinDetected ? openWinPopup : handleIncrementRow),
        incrementColumn: movesDisabled ? () => {} : (gameWinDetected ? openWinPopup : handleIncrementColumn),
        restart: restart,
        openInfoPopup: openInformationPopup,
        openStatsPopup: openStatsPopup,
        openSettingsPopup: openSettingsPopup,
        doneAnimatingGameStart: doneAnimatingGameStart,
        doneAnimatingGameWin: doneAnimatingGameWin,
        setAnimationSettings: setAnimationSettings
      }}
      isArchive={isArchive}
    />
    {
      numberPoses && 
      <svg style={styles.numberContainer} viewBox={`0 0 300 300`}>
        <AnimatePresence initial={false} custom={{
          lastMove: lastMove
        }}>
            {
              numberPoses.map((numberPos, idx) => {
                if (true) {
                  return <motion.g 
                    key={`${numberPos.id}-${numberPos.appearing}`}
                    id={`${numberPos.id}-${numberPos.appearing}`}
                    initial="initial"
                    custom={{
                      lastMove: lastMove,
                      growing: getSizing(numberPos.id) == 'growing',
                      shrinking: getSizing(numberPos.id) == 'shrinking'
                    }}
                    variants={numberVariants(numberPos)}
                    animate="animate"
                    transition={{ duration: 0.5 }}
                    exit="exit"
                  >
                      <Number n={numberPos.n} gridSize={300} key={`${numberPos.n}-${numberPos.appearingToggle}`}></Number>
                  </motion.g>
                }
              })
            }
        </AnimatePresence>
      </svg>
    }
    <AnimatePresence initial={false}>
    { popupContents && 
        <motion.div 
          key='infopopup'
          ref={popupRef} 
          style={styles.popup}
          initial={{ scale: .1}}
          animate={{
            scale: 1,
            transition: {
              duration: .3,
              ease: 'backOut'
            }
          }}
          exit={{
            scale: .1,
            transition: {
              ease: 'backIn'
            }}}
        >
          {popupContents}
        </motion.div>
    }
    { winPopupContents && 
        <motion.div 
          key={'winPopup'}
          ref={popupRef} 
          style={styles.winPopup}
          initial={{ scale: .1 }}
          animate={{
            scale: 1,
            transition: {
              duration: .3,
              ease: 'backOut'
            }
          }}
          exit={{
            scale: .1,
            transition: {
              ease: 'backIn'
            }}}
        >
          {winPopupContents}
        </motion.div>
    }
    </AnimatePresence>
  </div>;
};

export default GameContainer;
