import React, { useState, useEffect, useRef } from 'react'
import { motion, AnimatePresence } from 'framer-motion';

const colors = ["#4A837F","#569278","#8AB29A", "#A1CEA0","#C5DBB8", "#B7E8C5","#A8DFD2","#8DC7B9","#569FA9"]

const textStyle = {
  userSelect: 'none',
  WebkitUserSelect: 'none',
  MozUserSelect: 'none',
  msUserSelect: 'none',
  background: 'none', 
  pointerEvents: 'none'
};

const MyScoreView = ({size, scoreInfo}) => {
  const containerStyle = {
    width: '100%', 
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  }

  const scoreContainer = {
    fontFamily: 'ProtoMono-Regular',
    textAlign: 'center',
    flexBasis: '25%',
    alignItems: 'top',
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    paddingBottom: '2%',
    fontSize: `${size * .1}px`,
    color: '#314f4d'
  }

  const scoreLabel = {
    fontFamily: 'ProtoMono-Regular',
    textAlign: 'center',
    fontSize: `${size * .03}px`,
    color: '#314f4d'
  }

  const scoreNumber = {
    display: "inline-block",
    fontFamily: 'ProtoMono-Regular',
    fontSize: `${size * .1}px`,
    marginTop: "3%",
    padding: "2% 4%",
    color: "#314f4d",
    borderRadius: "5px",
    cursor: "pointer"
  }

  return <div style={containerStyle}>
    <div style={scoreContainer}>
      <div style={scoreNumber}>
        {(scoreInfo.numPlayed || 0)}
      </div>
      <div style={scoreLabel}>
        # Solved
      </div>
    </div>
    <div style={scoreContainer}>
      <div style={scoreNumber}>
        {scoreInfo.currentStreak || 0}
      </div>
      <div style={scoreLabel}>
        {'Current\nStreak'}
      </div>
    </div>
    <div style={scoreContainer}>
      <div style={scoreNumber}>
        {scoreInfo.maxStreak || 0}
      </div>
      <div style={scoreLabel}>
        {'Max streak'}
      </div>
    </div>
  </div>
}

export default MyScoreView;


